/* eslint-disable import/no-anonymous-default-export */
export default {
  MAX_ATTACHMENT_SIZE: 7000000,
  s3: {
    REGION: "ap-southeast-2",
    BUCKET: "evrimahub-image-uploads-prod"
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://fgma8ch143.execute-api.ap-southeast-2.amazonaws.com/Prod/api/",
    KEY: "BqG0muGQTn7XK4zUrrLr61HWxUjfx2yg3loAY321"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_8M7nrkdPn",
    APP_CLIENT_ID: "tb86qt4a0d0a2i936afe9uhat",
    IDENTITY_POOL_ID: "ap-southeast-2:e6758662-9545-4e04-b94b-e7c547307ed2"
  },
  axios: {
    BASE_ENDPOINT_URL: "https://fgma8ch143.execute-api.ap-southeast-2.amazonaws.com/Prod/api/",
  }
};