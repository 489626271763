export function buildAuthObject(user, auth=true) {
    if(auth) {
        return (
            {
              isAuthenticated: true,
              userDetails: user.idToken.payload,
              isAdmin: getGroupName(user.idToken.payload) === "admin",
              isSiteUser: getGroupName(user.idToken.payload) === "site",
              isSponsor: getGroupName(user.idToken.payload) === "sponsor",
              isPatient: getGroupName(user.idToken.payload) === "patient"
            });
    } else {
        return (
            {
                isAuthenticated: false,
                userDetails: {sub: ""},
                isAdmin: false,
                isSiteUser: false,
                isSponsor: false,
                isPatient: false
            });
    }
}

function getGroupName(user) {
    var groupName = "";
    if(user["cognito:groups"]) {
      groupName = user["cognito:groups"][0]
    }

    switch(groupName) {
      case "EvrimaAdmins":
        return "admin";
      case "EvrimaSiteUsers":
        return "site";
      case "EvrimaSponsors":
        return "sponsor";
      default:
        return "patient";
    }
  }