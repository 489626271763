import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./ContactPipeline.css";
import statuses from '../constants/statuses';
import moment from 'moment';

export default function ContactPipeline({ patients, onClickPatient, recordPatientMoved }) {

  const data = statuses.map((col) => {
    return {
      title: col.text,
      patients: patients.sort((a, b) => (a.lastContacted || moment()).isAfter(b.lastContacted || moment()) ? 1 : -1).filter(pat => pat.trialData.status === col.key)
    }
  });

  const grid = 8;

  function getItemStyle(isDragging, draggableStyle) {
    return ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? "lightgreen" : "white",
      borderRadius: 3,
      boxShadow: "0 1px 2px 0 rgba(9,30,66,.25)",

      // styles we need to apply on draggables
      ...draggableStyle
    });
  }

  function getListStyle(isDraggingOver) {
    return ({
      background: isDraggingOver ? "lightblue" : "#f4f5f7",
      border: isDraggingOver ? '1px dashed #2a378d' : '1px solid transparent',
      padding: grid,
      height: '100%'
    });
  }

  function onDragEnd(result) {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    if(source.droppableId !== destination.droppableId){
      const newStatus = statuses[destination.droppableId].key;
      recordPatientMoved(draggableId, newStatus);
    }
    //TODO: Handle when the source/destination are the same. May be handled in the same way of we have sort order in the DB
  }

  return (
    <div className="contactPipeline">
      <div style={{ display: "flex" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {(data).map((el, ind) => (
            <div className="flexCol" key={'heading' + ind}>
              <div className="cardHead">
                <span className="heavyText">
                  {el.title}
                </span>
              </div>
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {el.patients.map((item, index) => (
                      <Draggable
                        key={item.userData.cognitoUserSub}
                        draggableId={item.userData.cognitoUserSub}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            onClick={() => onClickPatient(item)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around"
                              }}
                            >
                              {`${item.userData.patientId}`}
                            </div>
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around"
                                }}
                              >
                              {`${item.lastContacted ? item.lastContacted.fromNow() : ''} (${item.contactData.length})`}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}