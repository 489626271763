import React, { useState, useEffect, Fragment } from "react";
import AxAPI from "../libs/axiosLib";
import { useAppContext } from "../libs/contextLib";
import {
    FormGroup,
    FormControl,
    ControlLabel,
    PageHeader,
    Panel,
    Checkbox,
    Radio,
    Table,
    Button,
    Modal,
    Glyphicon
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import NewImage from "./NewImage";
import "./AdminPanel.css";

export default function AdminPanel() {
    const { authentication } = useAppContext();
    const [imageUploadUser, setImageUploadUser] = useState({cognitoUserSub: ''});
    const [isLoading, setIsLoading] = useState(false);
    const [sites, setSites] = useState([]);
    const [trials, setTrials] = useState([]);
    const [moveSites, setMoveSites] = useState([]);
    const [moveTrialId, setMoveTrialId] = useState('');
    const [moveSiteId, setMoveSiteId] = useState('');
    const [smsNumbers, setSmsNumbers] = useState('');
    const [smsMessageText, setSmsMessageText] = useState('');
    const [smsHighPriority, setSmsHighPriority] = useState(false);
    const [siteGroupAccess, setSiteGroupAccess] = useState(false);
    const [adminGroupAccess, setAdminGroupAccess] = useState(false);
    const [sponsorGroupAccess, setSponsorGroupAccess] = useState(false);
    const [showEditAccessModal, setShowEditAccessModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showMoveUserModal, setShowMoveUserModal] = useState(false);
    const [userAccessToEdit, setUserAccessToEdit] = useState(null);
    const [userToEdit, setUserToEdit] = useState(null);
    const [userToMove, setUserToMove] = useState(null);
    const [usersSortDir, setUsersSortDir] = useState(1);
    const [usersSortHeader, setUsersSortHeader] = useState('familyName');
    const [userDetails, setUserDetails] = useState([]);
    const [searchUserFields, handleSearchUserFieldsChange] = useFormFields({
        searchEmail: "",
        searchFamilyName: "",
        searchPatientId: "",
        searchUserSub: ""
    });
    const [newUserFields, handleNewUserFieldChange] = useFormFields({
        email: "",
        givenName: "",
        familyName: "",
        phoneNumber: "",
        site: "",
        patientId: "",
        accessLevel: ""
    });
    const [editUserFields, handleEditUserFieldChange] = useFormFields({
        givenName: "",
        familyName: "",
        phoneNumber: "",
        email: "",
        patientId: ""
    });

    useEffect(() => {
        async function onLoad() {
          try {
            await loadData();
          } catch (e) {
            onError(e);
          }
        }
             
        onLoad();
    }, []);

    async function loadData() {
        setIsLoading(true);
        
        var url = "trialdata/getalltrials";
        var trialsResult = await AxAPI.get(url);
        setTrials(trialsResult.data.results);
        
        // setTrialnames(trialsResult.data.results.map((trial) => trial.trialName + ' - ' + trial.trialStatus))

        url = "trialdata/getsites?includeinactive=true";
        var sitesResult = await AxAPI.get(url);
        setSites(sitesResult.data);
        
        //setSiteNames(sitesResult.data.map((site) => site.siteName));
        
        // ### USER LOAD
        // url = "users/getusers";
        // var usersResult = await AxAPI.get(url);
        // var newUserDetails = [];
        // usersResult.data.results.forEach(user => {
        //     var newUser = {
        //         cognitoUserSub: user.cognitoUserSub,
        //         patientId: user.patientId,
        //         email: user.email,
        //         givenName: user.givenName,
        //         familyName: user.familyName,
        //         groupNamesString: user.groups.join(' '),
        //         location: user.location,
        //         siteName: sitesResult.data.find(element => element.siteId === user.location).siteName
        //     };
        //     newUserDetails.push(newUser);
        // });
        // setUserDetails(newUserDetails);
        // ### USER LOAD

        setIsLoading(false);
    }
    
    //#region 
    // async function handleNewSite(event) {
    //     event.preventDefault();

    //     var siteData = {
    //         SiteName: newSiteName
    //     };

    //     await AxAPI.post("trialdata/addnewsite", siteData);
    //     setNewSiteName('');
    //     await loadData();
    // }

    // async function handleNewTrial(event) {
    //     event.preventDefault();

    //     var trialData = {
    //         trialName: newTrialName,
    //         trialStatus: newTrialStatus,
    //         displayOrder: 9
    //     };

    //     await AxAPI.post("trialdata/addnewtrial", trialData);
    //     setNewTrialName('');
    //     setNewTrialStatus('');
    //     await loadData();
    // }

    // function validateNewSiteForm() {
    //     return newSiteName.length > 0;
    // }

    // function validateNewTrialForm() {
    //     return newTrialName.length > 0 && newTrialStatus.length > 0;
    // }
    // async function handleDeleteUser(userToDelete) {
    //     if(window.confirm('Are you sure you wish to delete this user? This cannot be undone!')) {
    //         console.log('User would be deleted here');
    //         await loadData();
    //     }
    // }

    // async function handleSelectReportTrial(trialId) {
    //     setIsLoading(true);
    //     resetTrialReport();
    //     setPatientReportSites([]);
    //     setShowNoSitesAlert(false);

    //     setPatientReportTrialId(trialId);
    //     if(trialId.length === 0) {
    //         setIsLoading(false);
    //         return;
    //     }
    //     var trialName = trials.find(element => element.trialId === trialId).trialName;
    //     setPatientReportTrialName(trialName);
    //     setPatientReportSites([]);
    //     setShowNoSitesAlert(false);
        
    //     var url = `trialdata/gettrialsites?trialid=${trialId}`;
    //     var sitesResult = await AxAPI.get(url);
    //     var reportingSitesArray = [];
    //     sitesResult.data.results.forEach(trialSite => {
    //         var newReportingSite = {
    //             id: trialSite.id,
    //             siteId: trialSite.siteId,
    //             trialId: trialSite.trialId,
    //             siteName: sites.find(element => element.siteId === trialSite.siteId).siteName
    //         };
    //         reportingSitesArray.push(newReportingSite);
    //     });
    //     if(reportingSitesArray.length > 0) {
    //         setPatientReportSites(reportingSitesArray);
    //     } else {
    //         setPatientReportSites([]);
    //         setShowNoSitesAlert(true);
    //     }

    //     setIsLoading(false);
    // }

    // async function handleSelectReportSite(siteId) {
    //     resetTrialReport();
    //     setPatientReportSiteId(siteId);
    // }

    // function resetTrialReport() {
    //     setPatientReportReady(false);
    //     setPatientReportData([]);
    //     setShowNoPatientReportAlert(false);
    // }

    // async function handleGetPatientReport(event) {
    //     event.preventDefault();
        
    //     setIsLoading(true);
    //     resetTrialReport();

    //     var url = `reporting/gettrialreport?trialid=${patientReportTrialId}&siteid=${patientReportSiteId}`;
    //     var report = await AxAPI.get(url);
    //     var reportOutput = [];
        
    //     if(report.data.results.length !== 0) {
    //         report.data.results.forEach(reportRow => {
    //             var newReportRow = {
    //                 'Site Name': sites.find(element => element.siteId === reportRow.siteId).siteName,
    //                 'First Name': userDetails.find(element => element.cognitoUserSub === reportRow.patientInternalId).givenName,
    //                 'Last Name': userDetails.find(element => element.cognitoUserSub === reportRow.patientInternalId).familyName,
    //                 'Patient ID': userDetails.find(element => element.cognitoUserSub === reportRow.patientInternalId).patientId,
    //                 'Status': reportRow.patientStatus,
    //                 'Screening Date': reportRow.screeningDate,
    //                 'Screening Date 2': reportRow.screeningDate2,
    //                 'Screening Number': reportRow.screeningNumber,
    //                 'Randomisation Number': reportRow.randomisationNumber,
    //                 'Last Updated': reportRow.lastUpdatedDateTime,
    //                 'Last Updated User': userDetails.find(element => element.cognitoUserSub === reportRow.lastUpdatedUserId).email,
    //                 'Disqualification Reason': reportRow.disqualificationReason,
    //                 'Disqualification Other': reportRow.disqualificationReasonOther,
    //                 'ICF Sent': reportRow.icfSent,
    //                 'Notified DateTime': reportRow.notifiedDateTime,
    //                 'Notification Template ID': reportRow.notificationTemplateId,
    //                 'Contacts': reportRow.contacts,
    //                 'Total Contacts': reportRow.totalContacts
    //             };
    //             reportOutput.push(newReportRow);
    //         });
            
    //         setPatientReportData(reportOutput);
    //         setPatientReportReady(true);
    //     } else {
    //         resetTrialReport();
    //         setShowNoPatientReportAlert(true);
    //     }

    //     setIsLoading(false);
    // }

    // function resetImageReport() {
    //     setImageReportReady(false);
    //     setImageReportData([]);
    //     setShowNoImageReportAlert(false);
    // }

    // function resetPatientImageReport() {
    //     setPatientImageReportReady(false);
    //     setPatientImageReportData([]);
    //     setShowNoPatientImageReportAlert(false);
    // }

    // async function handleGetImageReports() {
    //     setIsLoading(true);

    //     var url = 'reporting/getimagesreport';
    //     var report = await AxAPI.get(url);
    //     var reportOutput = [];
    //     if(report.data.results.length !== 0) {
    //         report.data.results.forEach(reportRow => {
    //             var newReportRow = {
    //                 'Trial Name': trials.find(element => element.trialId === reportRow.trialId).trialName,
    //                 'Site Name': sites.find(element => element.siteId === reportRow.siteId).siteName,
    //                 'Number of Patients (unique)': reportRow.numberPatients,
    //                 'Pending Images': reportRow.numberImagesPending,
    //                 'Approved Images': reportRow.numberImagesApproved,
    //                 'Disqualified Images': reportRow.numberImagesDisqualified
    //             };
    //             reportOutput.push(newReportRow);
    //         });
            
    //         setImageReportData(reportOutput);
    //         setImageReportReady(true);
    //     } else {
    //         resetImageReport();
    //         setShowNoImageReportAlert(true);
    //     }

    //     url = 'reporting/getpatientimagereport';
    //     report = await AxAPI.get(url);
    //     reportOutput = [];
    //     if(report.data.results.length !== 0) {
    //         report.data.results.forEach(reportRow => {
    //             var newReportRow = {
    //                 'Trial Name': trials.find(element => element.trialId === reportRow.trialId).trialName,
    //                 'Site Name': sites.find(element => element.siteId === reportRow.siteId).siteName,
    //                 'Patient Name': `${userDetails.find(element => element.cognitoUserSub === reportRow.patientUserSub).givenName} ${userDetails.find(element => element.cognitoUserSub === reportRow.patientUserSub).familyName}`,
    //                 'Patient ID': userDetails.find(element => element.cognitoUserSub === reportRow.patientUserSub).patientId,
    //                 'Pending Images': reportRow.numberImagesPending,
    //                 'Approved Images': reportRow.numberImagesApproved,
    //                 'Disqualified Images': reportRow.numberImagesDisqualified
    //             };
    //             reportOutput.push(newReportRow);
    //         });
            
    //         setPatientImageReportData(reportOutput);
    //         setPatientImageReportReady(true);
    //     } else {
    //         resetPatientImageReport();
    //         setShowNoPatientImageReportAlert(true);
    //     }
    //     setIsLoading(false);
    // }
    //#endregion

    function validateSmsForm() {
        return smsNumbers.length > 0 && smsMessageText.length > 0;
    }

    function validateNewUserForm() {
        return newUserFields.email.length > 0
         && newUserFields.givenName.length > 0
         && newUserFields.familyName.length > 0
         && newUserFields.site.length > 0;
    }

    function validateSearchUserForm() {
        return true;
        // return searchUserFields.searchEmail.length > 0
        //  || searchUserFields.searchFamilyName.length > 0
        //  || searchUserFields.searchPatientId > 0
        //  || searchUserFields.searchUserSub > 0;
    }

    async function handleSendSms(event) {
        event.preventDefault();

        var nums = smsNumbers.split(',');
        var smsRequest = {
            Numbers: nums,
            MessageText: smsMessageText,
            SenderName: 'Evrima',
            Priority: smsHighPriority ? 'Transactional' : 'Promotional'
        }
        var response = await AxAPI.post("notifications/sendsmsmessages", smsRequest);
        console.log(response);
        window.alert(nums.length + ' sms messages were queued to be sent.\nYou can check the Cloudwatch logs for failures.');
        // TODO: data is an array, so we should check the status for each number here.
    }

    async function handleNewUser(event) {
        event.preventDefault();

        setIsLoading(true);
        var newGroup = '';
        switch(newUserFields.accessLevel) {
            case 'sponsor':
                newGroup = 'EvrimaSponsors';
                break;
            case 'site':
                newGroup = 'EvrimaSiteUsers';
                break;
            case 'admin':
                newGroup = 'EvrimaAdmins';
                break;
            default:
                break;
        };
        var createUserRequest = {
            userId: authentication.userDetails.sub,
            newUser: {
                givenName: newUserFields.givenName,
                familyName: newUserFields.familyName,
                phoneNumber: newUserFields.phoneNumber,
                email: newUserFields.email,
                location: newUserFields.site,
                patientId: newUserFields.patientId,
                groups: [newGroup]
            }
        };
        var response = await AxAPI.post("users/createuser", createUserRequest);
        console.log(response);
        // TODO: Add proper alert here.
        window.alert("User added");

        await loadData();
        setIsLoading(false);
    }

    async function handleChangeUserGroups(event) {
        event.preventDefault();

        setIsLoading(true);
        var setUserGroupRequest = {
            userId: authentication.userDetails.sub,
            groupUserId: userAccessToEdit.cognitoUserSub,
            groupName: adminGroupAccess ? 'EvrimaAdmins' : siteGroupAccess ? 'EvrimaSiteUsers' : sponsorGroupAccess ? 'EvrimaSponsors' : ''
        };
        var response = await AxAPI.post("users/setusergroup", setUserGroupRequest);
        setUserAccessToEdit(null);
        setSiteGroupAccess(false);
        setAdminGroupAccess(false);
        setSponsorGroupAccess(false);
        if(response.data.successful) {
            window.alert('Access added. User must log out and back in now.');
            await loadData();
        } else {
            window.alert('Yeah something went wrong there. Check the logs.');
        }

        setIsLoading(false);
    }

    async function handleUpdateUserDetails(event) {
        event.preventDefault();

        setIsLoading(true);

        var updateUserRequest = {
            cognitoUserSub: userToEdit,
            givenName: editUserFields.givenName,
            familyName : editUserFields.familyName,
            phoneNumber: editUserFields.phoneNumber,
            email: editUserFields.email,
            patientId: editUserFields.patientId
        };

        var response = await AxAPI.post("users/updateuser", updateUserRequest);
        setUserToEdit(null);

        if(response.data.successful) {
            window.alert('User details updated.');
            await loadData();
        } else {
            window.alert('Yeah something went wrong. Check the logs.');
        }

        setIsLoading(false);
    }

    async function handleMoveUser(event) {
        event.preventDefault();

        setIsLoading(true);

        var moveUserRequest = {
            UserId: authentication.userDetails.sub,
            PatientUserSub: userToMove,
            SiteId: moveSiteId,
            TrialId: moveTrialId
        };

        try {
            var response = await AxAPI.post("users/moveuser", moveUserRequest);
            if(response.data.successful) {
                window.alert('User moved.');
                await loadData();
            } else {
                window.alert('Yeah something went wrong. Check the logs.');
            }
        } catch (error) {
            window.alert(error);
        }
        finally {
            handleCloseMoveUserModal();
            setIsLoading(false);
        }
    }

    function handleShowEditAccessModal(passedUser) {
        setUserAccessToEdit(passedUser);
        setSiteGroupAccess(passedUser.groupNamesString === 'Site User');
        setAdminGroupAccess(passedUser.groupNamesString === 'Admin');
        setSponsorGroupAccess(passedUser.groupNamesString === 'Sponsor');
        setShowEditAccessModal(true);
    }

    function handleCloseEditAccessModal() {
        setShowEditAccessModal(false);
    }

    function handleShowEditUserModal(passedUser) {
        setUserToEdit(passedUser.cognitoUserSub);
        editUserFields.givenName = passedUser.givenName;
        editUserFields.familyName = passedUser.familyName;
        editUserFields.phoneNumber = passedUser.phoneNumber;
        editUserFields.email = passedUser.email;
        editUserFields.patientId = passedUser.patientId;
        setShowEditUserModal(true);
    }

    function handleCloseEditUserModal() {
        setUserToEdit(null);
        setShowEditUserModal(false);
    }

    function handleShowMoveUserModal(passedUser) {
        setUserToMove(passedUser.cognitoUserSub);
        setMoveSiteId(passedUser.location);
        setMoveTrialId(passedUser.trialId);
        setShowMoveUserModal(true);
    }

    function handleCloseMoveUserModal() {
        setUserToMove(null);
        setMoveSites([]);
        setMoveSiteId('');
        setMoveTrialId('');
        setShowMoveUserModal(false);
    }

    function changeUsersSort(headerName) {
        var newSortDir = headerName === usersSortHeader ? -usersSortDir : 1;
        setUsersSortHeader(headerName);
        setUsersSortDir(newSortDir);
    }

    function getUsersArray() {
        var usersArray;
        switch(usersSortHeader)
        {
            case 'siteName':
                usersArray = [].concat(userDetails).sort((a,b) => a.siteName > b.siteName ? usersSortDir : -usersSortDir);
                break;
            case 'group':
                usersArray = [].concat(userDetails).sort((a,b) => a.groupNamesString > b.groupNamesString ? usersSortDir : -usersSortDir);
                break;
            case 'familyName':
                usersArray = [].concat(userDetails).sort((a,b) => a.familyName > b.familyName ? usersSortDir : -usersSortDir);
                break;
            case 'givenName':
                usersArray = [].concat(userDetails).sort((a,b) => a.givenName > b.givenName ? usersSortDir : -usersSortDir);
                break;
            case 'email':
            usersArray = [].concat(userDetails).sort((a,b) => a.email > b.email ? usersSortDir : -usersSortDir);
            break;
            default:
                break;
        }
        return usersArray;
    }

    async function handleClearAccess() {
        if(window.confirm('This will remove all group access for this user. Are you sure?')) {
            setAdminGroupAccess(false);
            setSiteGroupAccess(false);
            setSponsorGroupAccess(false);

            var cufgRequest = {
                userId : authentication.userDetails.sub,
                clearUserId : userAccessToEdit.cognitoUserSub
            };
            setUserAccessToEdit(null);
            var response = await AxAPI.post("users/clearuserfromgroups", cufgRequest);
            if(response.data.successful) {
                window.alert('Access cleared. User must log out and back in now.');
                await loadData();
            } else {
                window.alert('Yeah something went wrong there. Check the logs.');
            }
            setShowEditAccessModal(false);
        }
    }

    function handleSetImageUploadUser(userId) {
        var uploadUser = userDetails.find((element => element.cognitoUserSub === userId));
        if(uploadUser) {
            setImageUploadUser(uploadUser);
        } else {
            setImageUploadUser({cognitoUserSub: ''});
        }
    }

    async function handleSearchUsers(event) {
        event.preventDefault();

        if(searchUserFields.searchEmail === "" && searchUserFields.searchFamilyName === "" && searchUserFields.searchPatientId === "" && searchUserFields.searchUserSub === "") {
            if(!window.confirm("WARNING! Searching with no filters will result in the entire user list being returned, and it's HUGE. Do you really want to do this?")) {
                return;
            }
        }

        setIsLoading(true);
        
        var searchReq = {
            userId: authentication.userDetails.sub,
            email: searchUserFields.searchEmail ?? "",
            familyName: searchUserFields.searchFamilyName ?? "",
            patientId: searchUserFields.searchPatientId ?? "",
            cognitoUserSub: searchUserFields.searchUserSub ?? ""
        };

        var response = await AxAPI.post("users/usersearch", searchReq);
        if(response.data.successful) {
            var newUserDetails = [];
            response.data.results.forEach(user => {
                var newUser = {
                    cognitoUserSub: user.cognitoUserSub,
                    patientId: user.patientId,
                    email: user.email,
                    givenName: user.givenName,
                    familyName: user.familyName,
                    phoneNumber: user.phoneNumber,
                    groupNamesString: user.groups.join(' '),
                    location: user.location,
                    siteName: user.location === 'Unknown' ? 'Unknown' : sites.find(element => element.siteId === user.location).siteName
                };
                newUserDetails.push(newUser);
            });
            setUserDetails(newUserDetails);
        } else {
            setUserDetails([]);
            if(response.data.responseMessage === "User does not exist.") {
                window.alert('No user exists with that ID.');
            }
            else {
                window.alert('Yeah something went wrong there. Check the logs.');
            }
        }
        setIsLoading(false);
    }

    async function handleSelectMoveTrial(trialId) {
        setIsLoading(true);
        
        setMoveSites([]);
        setMoveSiteId('');
        setMoveTrialId(trialId);
        
        if(trialId !== '') {
            var url = `trialdata/gettrialsites?trialid=${trialId}`;
            var sitesResult = await AxAPI.get(url);
            var moveSitesArray = [];
            sitesResult.data.results.forEach(trialSite => {
                var newMoveSite = {
                    id: trialSite.id,
                    siteId: trialSite.siteId,
                    trialId: trialSite.trialId,
                    siteName: sites.find(element => element.siteId === trialSite.siteId).siteName
                };
                moveSitesArray.push(newMoveSite);
            });
            if(moveSitesArray.length > 0) {
                setMoveSites(moveSitesArray);
            }
        }
        setIsLoading(false);
    }

    function handleSelectMoveSite(siteId) {
        setMoveSiteId(siteId);
    }

    function renderAdminPanel() {
        return (
            <div className="AdminPanel">
                <PageHeader>Administration Panel {isLoading && <Glyphicon glyph="refresh" className="spinning" />}</PageHeader>
                <Panel id="userSearch">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>User Search</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div className="AdminPanel">
                                <form onSubmit={handleSearchUsers}>
                                    <FormGroup controlId="searchEmail" bsSize="large">
                                        <ControlLabel>Email (contains match)</ControlLabel>
                                        <FormControl
                                            autoFocus
                                            type="text"
                                            value={searchUserFields.searchEmail}
                                            onChange={handleSearchUserFieldsChange}
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="searchFamilyName" bsSize="large">
                                        <ControlLabel>Family Name (contains match)</ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={searchUserFields.searchFamilyName}
                                            onChange={handleSearchUserFieldsChange}
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="searchPatientId" bsSize="large">
                                        <ControlLabel>Patient ID (exact match)</ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={searchUserFields.searchPatientId}
                                            onChange={handleSearchUserFieldsChange}
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="searchUserSub" bsSize="large">
                                        <ControlLabel>Cognito ID (exact match)</ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={searchUserFields.searchUserSub}
                                            onChange={handleSearchUserFieldsChange}
                                        />
                                    </FormGroup>
                                    <LoaderButton
                                        block
                                        type="submit"
                                        bsSize="large"
                                        isLoading={isLoading}
                                        disabled={!validateSearchUserForm()}
                                        >
                                        Search
                                    </LoaderButton>
                                </form>
                                <div className="AdminPanel">
                                    Returned {userDetails.length} users.
                                </div>
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel id="uploadImage">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>Upload Image</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            {userDetails.length === 0 && <p>No users. You need to search first.</p>}
                            <FormGroup controlId="imageUpload" bsSize="lg">
                                <ControlLabel>Users</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    onChange={e => handleSetImageUploadUser(e.target.value)}
                                    value={imageUploadUser.cognitoUserSub}>
                                    <option key="none" value={{cognitoUserSub: ''}}></option>
                                    {[].concat(userDetails).sort((a,b) => a.familyName > b.familyName ? 1 : -1).map((user, index) => {
                                        return (<option key={index} value={user.cognitoUserSub}>{`${user.givenName} ${user.familyName} - ${user.siteName}`}</option>)
                                    })}
                                </FormControl>
                            </FormGroup>
                            {imageUploadUser.cognitoUserSub.length !== 0 && 
                                <NewImage passedUser={imageUploadUser} />
                            }
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Fragment>
                {/* <Panel id="reporting">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>Reporting</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <ControlLabel>Trial Image Reports</ControlLabel>
                            <LoaderButton
                                block
                                type="submit"
                                bsSize="large"
                                isLoading={isLoading}
                                onClick={handleGetImageReports}
                                >
                                Get Image Reports
                            </LoaderButton>
                            {imageReportReady && 
                                <Fragment>
                                    <h2>Site summary report</h2>
                                    <Table responsive condensed striped bordered hover>
                                        <thead>
                                            <tr key="header">
                                                <th>Trial Name</th>
                                                <th>Site Name</th>
                                                <th>Patients (Unique)</th>
                                                <th>Pending Images</th>
                                                <th>Approved Images</th>
                                                <th>Disqualified Images</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[].concat(imageReportData).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((site, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{site["Trial Name"]}</td>
                                                        <td>{site["Site Name"]}</td>
                                                        <td>{site["Number of Patients (unique)"]}</td>
                                                        <td>{site["Pending Images"]}</td>
                                                        <td>{site["Approved Images"]}</td>
                                                        <td>{site["Disqualified Images"]}</td>
                                                    </tr>)
                                            })}
                                        </tbody>
                                    </Table>
                                    <CSVLink data={imageReportData} filename={'TrialImages_report.csv'}>Save as CSV</CSVLink>
                                </Fragment>
                            }
                            {showNoImageReportAlert &&
                                <Fragment>
                                    <Alert bsStyle="warning">There are no results for that report.</Alert>
                                </Fragment>
                            }
                            {patientImageReportReady && 
                                <Fragment>
                                    <h2>Patient Image report</h2>
                                    <Table responsive condensed striped bordered hover>
                                        <thead>
                                            <tr key="header">
                                                <th>Trial Name</th>
                                                <th>Site Name</th>
                                                <th>Patient Name</th>
                                                <th>Patient ID</th>
                                                <th>Pending Images</th>
                                                <th>Approved Images</th>
                                                <th>Disqualified Images</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[].concat(patientImageReportData).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((site, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{site["Trial Name"]}</td>
                                                        <td>{site["Site Name"]}</td>
                                                        <td>{site["Patient Name"]}</td>
                                                        <td>{site["Patient ID"]}</td>
                                                        <td>{site["Pending Images"]}</td>
                                                        <td>{site["Approved Images"]}</td>
                                                        <td>{site["Disqualified Images"]}</td>
                                                    </tr>)
                                            })}
                                        </tbody>
                                    </Table>
                                    <CSVLink data={patientImageReportData} filename={'PatientImages_report.csv'}>Save as CSV</CSVLink>
                                </Fragment>
                            }
                            {showNoPatientImageReportAlert &&
                                <Fragment>
                                    <Alert bsStyle="warning">There are no results for that report.</Alert>
                                </Fragment>
                            }
                            <hr />
                            <form className="frmPanel" onSubmit={handleGetPatientReport}>
                                <div>
                                    <ControlLabel>Trial Patient Report</ControlLabel>
                                    <FormGroup controlId="reportingtrials" bsSize="lg">
                                        <ControlLabel>Trials</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            onChange={e => handleSelectReportTrial(e.target.value)}
                                            value={patientReportTrialId}>
                                            <option key="none" value=""></option>
                                            {[].concat(trials).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((trial, index) => {
                                                return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                                            })}
                                        </FormControl>
                                    </FormGroup>
                                    {patientReportSites.length !== 0 &&
                                    <Fragment>
                                        <FormGroup controlId="reportingsites" bsSize="lg">
                                            <ControlLabel>Sites</ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                onChange={e => handleSelectReportSite(e.target.value)}
                                                value={patientReportSiteId}>
                                                <option key="all" value="all">All sites</option>
                                                {[].concat(patientReportSites).sort((a,b) => a.siteName > b.siteName ? 1 : -1).map((site, index) => {
                                                    return (<option key={index} value={site.siteId}>{site.siteName}</option>)
                                                })}
                                            </FormControl>
                                        </FormGroup>
                                        <FormGroup controlId="reportingcontrols" bsSize="lg">
                                            <LoaderButton
                                                block
                                                type="submit"
                                                bsSize="large"
                                                isLoading={isLoading}
                                                >
                                                Get Report
                                            </LoaderButton>
                                        </FormGroup>
                                        {patientReportReady && 
                                            <Fragment>
                                                <CSVLink data={patientReportData} filename={`${patientReportTrialName}_report.csv`}>Save as CSV</CSVLink>
                                            </Fragment>
                                        }
                                        {showNoPatientReportAlert &&
                                            <Fragment>
                                                <Alert bsStyle="warning">There are no results for that report.</Alert>
                                            </Fragment>
                                        }
                                    </Fragment>}
                                    {showNoSitesAlert && 
                                        <Fragment>
                                            <Alert bsStyle="warning">There are no active sites for that trial.</Alert>
                                        </Fragment>
                                    }
                                </div>
                            </form>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel> */}
                </Fragment>
                <Panel id="sms">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>Send SMS Messages</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <form className="frmPanel" onSubmit={handleSendSms}>
                                    <div>
                                        <ControlLabel>Mobile Numbers (MUST be csv list and have country codes)</ControlLabel>
                                        <FormControl
                                            componentClass="textarea"
                                            className="fixedtextarea"
                                            value={smsNumbers}
                                            onChange={e => setSmsNumbers(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <ControlLabel>Message Text (each 140chars = 1 sms charge) {smsMessageText && smsMessageText.length + ' chars'}</ControlLabel>
                                        <FormControl
                                            componentClass="textarea"
                                            className="fixedtextarea"
                                            value={smsMessageText}
                                            onChange={e => setSmsMessageText(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <Checkbox
                                            inline
                                            checked={smsHighPriority}
                                            onChange={e => setSmsHighPriority(e.target.checked)}
                                            >High Priority (more expensive)
                                        </Checkbox>
                                    </div>
                                    <div>
                                        <LoaderButton
                                            className="btnSmall"
                                            type="submit"
                                            bsSize="large"
                                            bsStyle="success"
                                            isLoading={isLoading}
                                            disabled={!validateSmsForm()}
                                            >
                                            Send
                                        </LoaderButton>
                                    </div>
                            </form>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Fragment>
                {/* <Panel id="sites">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>
                            Add a new site
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <form className="frmPanel" onSubmit={handleNewSite}>            
                                <div>
                                    <ControlLabel>Existing Site Names</ControlLabel>
                                    <FormControl
                                        componentClass="textarea"
                                        className="fixedtextarea"
                                        readOnly={true}
                                        defaultValue={siteNames.join("\n")}
                                    />
                                </div>
                                <div>
                                    <ControlLabel>New Site Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        bsSize="large"
                                        value={newSiteName}
                                        onChange={e => setNewSiteName(e.target.value) }
                                    />
                                </div>
                                <div>
                                    <LoaderButton
                                        className="btnSmall"
                                        type="submit"
                                        bsSize="large"
                                        bsStyle="success"
                                        isLoading={isLoading}
                                        disabled={!validateNewSiteForm()}
                                        >
                                        Add
                                    </LoaderButton>
                                </div>
                            </form>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel id="trials">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>
                            Add a new trial
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <form className="frmPanel" onSubmit={handleNewTrial}>
                                <div>
                                    <ControlLabel>Existing Trial Names</ControlLabel>
                                    <FormControl
                                        componentClass="textarea"
                                        className="fixedtextarea"
                                        readOnly={true}
                                        defaultValue={trialNames.join("\n")}
                                    />
                                </div>
                                <div>
                                    <ControlLabel>Trial Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        bsSize="large"
                                        onChange={e => setNewTrialName(e.target.value)}
                                        value={newTrialName}
                                    />
                                </div>
                                <div>
                                    <ControlLabel>Trial Status</ControlLabel>
                                    <FormControl
                                        componentClass="select"
                                        bsSize="large"
                                        placeholder="Trial Status"
                                        onChange={e => setNewTrialStatus(e.target.value)}
                                        value={newTrialStatus}>
                                        <option value=""></option>
                                        <option value="Not Recruiting">Not yet recruiting</option>
                                        <option value="Recruiting">Recruiting</option>
                                    </FormControl>
                                </div>
                                <div>
                                    <LoaderButton
                                        className="btnSmall"
                                        type="submit"
                                        bsSize="large"
                                        bsStyle="success"
                                        isLoading={isLoading}
                                        disabled={!validateNewTrialForm()}
                                        >
                                        Add
                                    </LoaderButton>
                                </div>
                            </form>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel id="imageDisq">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>
                            Image disqualification reasons
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <form className="frmPanel" onSubmit={handleNewTrial}>
                                <div>
                                    <ControlLabel>Trials</ControlLabel>
                                    <FormControl
                                        bsSize="lg"
                                        componentClass="select"
                                        onChange={e => setImageDisqId(e.target.value)}
                                        value={imageDisqId}>
                                        <option key="all" value="all">All trials</option>
                                        {[].concat(trials).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((trial, index) => {
                                            return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                                        })}
                                    </FormControl>
                                </div>
                                <div>
                                    <LoaderButton
                                        className="btnSmall"
                                        type="submit"
                                        bsSize="large"
                                        bsStyle="success"
                                        isLoading={isLoading}
                                        disabled={!validateNewTrialForm()}
                                        >
                                        Add
                                    </LoaderButton>
                                </div>
                            </form>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel> */}
                </Fragment>
                <Panel id="newuser">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>
                            Add a new user
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <form onSubmit={handleNewUser}>
                                <FormGroup controlId="email" bsSize="large">
                                    <ControlLabel>Email</ControlLabel>
                                    <FormControl
                                        autoFocus
                                        type="email"
                                        value={newUserFields.email}
                                        onChange={handleNewUserFieldChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="givenName" bsSize="large">
                                    <ControlLabel>First Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleNewUserFieldChange}
                                        value={newUserFields.givenName}
                                    />
                                </FormGroup>
                                <FormGroup controlId="familyName" bsSize="large">
                                    <ControlLabel>Last Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleNewUserFieldChange}
                                        value={newUserFields.familyName}
                                    />
                                </FormGroup>
                                <FormGroup controlId="phoneNumber" bsSize="large">
                                    <ControlLabel>Phone Number</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleNewUserFieldChange}
                                        value={newUserFields.phoneNumber}
                                    />
                                </FormGroup>
                                <FormGroup controlId="site" bsSize="large">
                                    <ControlLabel>Trial Site Location (for Admins, pick any location)</ControlLabel>
                                    <FormControl
                                        componentClass="select"
                                        placeholder="Select your location"
                                        onChange={handleNewUserFieldChange}
                                        value={newUserFields.site}>
                                        <option key="none" value=""></option>
                                        {[].concat(sites).sort((a,b) => a.siteName > b.siteName ? 1 : -1).map((site, index) => {
                                            return (<option key={index} value={site.siteId}>{site.siteName}</option>)
                                        })}
                                    </FormControl>
                                </FormGroup>
                                <FormGroup controlId="patientId" bsSize="large">
                                    <ControlLabel>Patient ID (Not required for Admin, Site users or Sponsors)</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleNewUserFieldChange}
                                        value={newUserFields.patientId}
                                    />
                                </FormGroup>
                                <FormGroup controlId="accessLevel" bsSize="large">
                                    <ControlLabel>Access Level</ControlLabel>
                                    <FormControl
                                        componentClass="select"
                                        bsSize="large"
                                        placeholder="Access Level"
                                        onChange={handleNewUserFieldChange}
                                        value={newUserFields.accessLevel}>
                                        <option value="">Patient</option>
                                        <option value="sponsor">Sponsor</option>
                                        <option value="site">Site User</option>
                                        <option value="admin">Administrator</option>
                                    </FormControl>
                                </FormGroup>
                                <LoaderButton
                                    block
                                    type="submit"
                                    bsSize="large"
                                    isLoading={isLoading}
                                    disabled={!validateNewUserForm()}
                                    >
                                    Create
                                </LoaderButton>
                            </form>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel id="users">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>
                            Existing User Management
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            {userDetails.length === 0 && <p>No users. You need to search first.</p>}
                            <div>
                                <Table responsive condensed striped bordered hover>
                                    <thead>
                                        <tr key="header">
                                            <th>UserSub</th>
                                            <th className="sortableTableHeader" onClick={e => changeUsersSort("email")}>Email</th>
                                            <th className="sortableTableHeader" onClick={e => changeUsersSort("givenName")}>First Name</th>
                                            <th className="sortableTableHeader" onClick={e => changeUsersSort("familyName")}>Last Name</th>
                                            <th className="sortableTableHeader" onClick={e => changeUsersSort("siteName")}>Site Name</th>
                                            <th className="sortableTableHeader" onClick={e => changeUsersSort("group")}>Group</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getUsersArray().map((user, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{user.cognitoUserSub}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.givenName}</td>
                                                    <td>{user.familyName}</td>
                                                    <td>{user.siteName}</td>
                                                    <td>{user.groupNamesString}</td>
                                                    <td>
                                                        <Button onClick={e => handleShowEditUserModal(user)}>Edit User</Button>
                                                        <Button onClick={e => handleShowMoveUserModal(user)}>Move User</Button>
                                                        <Button onClick={e => handleShowEditAccessModal(user)}>Edit Access</Button>
                                                        {/* {user.groupNamesString === 'Site User' && <Button onClick={e => handleDeleteUser(user)}>Delete</Button>} */}
                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                {userToEdit && <Modal
                    dialogClassName="modalDialog"
                    bsSize="large"
                    show={showEditUserModal}
                    onHide={handleCloseEditUserModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleUpdateUserDetails}>
                            <div>
                                <FormGroup controlId="email" bsSize="large">
                                    <ControlLabel>Email</ControlLabel>
                                    <FormControl
                                        autoFocus
                                        type="email"
                                        value={editUserFields.email}
                                        onChange={handleEditUserFieldChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="givenName" bsSize="large">
                                    <ControlLabel>First Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleEditUserFieldChange}
                                        value={editUserFields.givenName}
                                    />
                                </FormGroup>
                                <FormGroup controlId="familyName" bsSize="large">
                                    <ControlLabel>Last Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleEditUserFieldChange}
                                        value={editUserFields.familyName}
                                    />
                                </FormGroup>
                                <FormGroup controlId="phoneNumber" bsSize="large">
                                    <ControlLabel>Phone Number</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleEditUserFieldChange}
                                        value={editUserFields.phoneNumber}
                                    />
                                </FormGroup>
                                <FormGroup controlId="patientId" bsSize="large">
                                    <ControlLabel>Patient ID (Not required for Admin, Site users or Sponsors)</ControlLabel>
                                    <FormControl
                                        type="text"
                                        onChange={handleEditUserFieldChange}
                                        value={editUserFields.patientId}
                                    />
                                </FormGroup>
                            </div>
                            <div>
                                <LoaderButton
                                    className="btnSmall"
                                    type="submit"
                                    bsSize="large"
                                    bsStyle="success"
                                    isLoading={isLoading}
                                >
                                Save
                                </LoaderButton>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>}
                {userToMove && <Modal
                    dialogClassName="modalDialog"
                    bsSize="large"
                    show={showMoveUserModal}
                    onHide={handleCloseMoveUserModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Move User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleMoveUser}>
                            <div>
                                <FormGroup controlId="movetrial" bsSize="lg">
                                    <ControlLabel>Trial</ControlLabel>
                                    <FormControl
                                        componentClass="select"
                                        bsSize="lg"
                                        onChange={e => handleSelectMoveTrial(e.target.value)}
                                        value={moveTrialId}>
                                        <option key="none" value=""></option>
                                        {[].concat(trials).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((trial, index) => {
                                            return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                                        })}
                                    </FormControl>
                                </FormGroup>
                                {moveSites.length !== 0 &&
                                    <Fragment>
                                        <FormGroup controlId="movesite" bsSize="lg">
                                            <ControlLabel>Site</ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                onChange={e => handleSelectMoveSite(e.target.value)}
                                                value={moveSiteId}>
                                                <option key="none" value=""></option>
                                                {[].concat(moveSites).sort((a,b) => a.siteName > b.siteName ? 1 : -1).map((site, index) => {
                                                    return (<option key={index} value={site.siteId}>{site.siteName}</option>)
                                                })}
                                            </FormControl>
                                        </FormGroup>
                                        {moveSiteId.length !== 0 &&
                                            <FormGroup>
                                                <LoaderButton
                                                    block
                                                    type="submit"
                                                    bsSize="lg"
                                                    isLoading={isLoading}
                                                    >
                                                    Move
                                                </LoaderButton>
                                            </FormGroup>
                                        }
                                    </Fragment>
                                }
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>}
                {userAccessToEdit && <Modal
                    dialogClassName="modalDialog"
                    bsSize="large"
                    show={showEditAccessModal}
                    onHide={handleCloseEditAccessModal}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Edit User Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleChangeUserGroups}>
                        <div>
                            <Radio
                                name="accessRadioGroup"
                                inline
                                bsSize="large"
                                checked={sponsorGroupAccess}
                                onChange={e => setSponsorGroupAccess(e.target.checked)}
                                >Sponsor Access
                            </Radio>
                            <Radio
                                name="accessRadioGroup"
                                inline
                                bsSize="large"
                                checked={siteGroupAccess}
                                onChange={e => setSiteGroupAccess(e.target.checked)}
                                >Site User Access
                            </Radio>
                            <Radio
                                name="accessRadioGroup"
                                inline
                                bsSize="large"
                                checked={adminGroupAccess}
                                onChange={e => setAdminGroupAccess(e.target.checked)}
                                >Admin Access
                            </Radio>
                        </div>
                        <div>
                            <LoaderButton
                                className="btnSmall"
                                type="submit"
                                bsSize="large"
                                bsStyle="success"
                                isLoading={isLoading}
                            >
                            Save
                            </LoaderButton>
                            <LoaderButton
                                className="btnSmall"
                                bsSize="large"
                                bsStyle="warning"
                                isLoading={isLoading}
                                onClick={handleClearAccess}
                            >
                            Clear Access
                            </LoaderButton>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseEditAccessModal}>Close</Button>
                </Modal.Footer>
                </Modal>}
            </div>
        );
    }

    return (
        <div>
            {renderAdminPanel()}
        </div>
    );
}