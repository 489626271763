import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import AxAPI from "../libs/axiosLib";
import { useHistory, useLocation } from "react-router-dom";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { buildAuthObject } from "../libs/authLib";
import "./Signup.css";

export default function Signup() {
  var query = useQuery();
  var rEmail = query.get("rEmail");
  var pEmail = query.get("pemail");
  var pFirstName = query.get("pfirstname");
  var pLastName = query.get("plastname");
  var pSiteCode = query.get("psitecode");
  var pPatientId = query.get("ppatientid");
  var pTrialId = query.get("pTrialId");
  if(!pTrialId) {
    pTrialId = query.get("ptrialid");
  }
  
  const [fields, handleFieldChange] = useFormFields({
    email: pEmail ?? rEmail ?? "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    firstName: pFirstName ?? "",
    lastName: pLastName ?? "",
    site: pSiteCode ?? "",
    patientId: pPatientId ?? ""
  });

  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { setAuthentication } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [loadingSites, setLoadingSites] = useState(false);

  useEffect(() => {
    getSites(pTrialId);
  }, [pTrialId]);
  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  async function getSites(trialName) {
    setLoadingSites(true);
    // Get all of the trials in the system.
    var trialsUrl = "trialdata/getalltrials";
    var trialsResult = await AxAPI.get(trialsUrl);

    // Check for the 2 specific ones we were passing as text initially.
    var trialGuid = '';
    if(trialName === 'Toe Fungus - Hexima') {
      trialGuid = 'f3ba9747-b9d9-4871-aa58-f1c6ca15867b';
    }
    if(trialName === 'Psoriasis') {
      trialGuid = '15fc56b8-29f2-4a64-ab9c-8828f084a2ee'
    }
    
    // If we didn't match on name, just set it to whatever was passed, so we can check below if it's a valid guid
    if(trialGuid === '') {
      trialGuid = trialName;
    }
    
    // Verify that the guid that's been either passed or matched is in the set of trials we got earlier
    var name = trialsResult.data.results.find(element => element.trialId === trialGuid);
    var trialId = '';
    if(name) {
      trialId = trialGuid;
    }

    // If we had a valid trialId passed, use that. Otherwise just get all the sites.
    var trialGuidParam = trialId === '' ? '' : `?trialid=${trialId}`;
    var url = `trialdata/getsites${trialGuidParam}`;
    var result = await AxAPI.get(url);
      
    setSites(result.data);
    setLoadingSites(false);
  }

  function validateForm() {
    return (false);
    // NB. The signup form has been disabled for now.
    //   fields.email.length > 0 &&
    //   fields.password.trim().length > 0 &&
    //   fields.firstName.length > 0 &&
    //   fields.lastName.length > 0 &&
    //   fields.site.length > 0 &&
    //   fields.password.trim() === fields.confirmPassword.trim()
    // );
  }

  function validateConfirmationForm() {
    return false; // NB. The signup form has been disabled for now.
    //return fields.confirmationCode.length > 0 && fields.password.trim().length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      var patientId = "999999"; // TODO: This needs to be rectified so we're not just defaulting for blank patientId values.
      if(fields.patientId.length !== 0) {
        patientId = fields.patientId;
      }
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password.trim(),
        attributes: {
            given_name: fields.firstName,
            family_name: fields.lastName,
            "custom:Location": fields.site,
            "custom:PatientId": patientId,
            // other custom attributes
          },
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      var response = await Auth.signIn(fields.email, fields.password.trim());
  
      setAuthentication(buildAuthObject(response.signInUserSession));
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="password" bsSize="large">
        <ControlLabel>Password</ControlLabel>
          <FormControl
            autoFocus
            type="password"
            onChange={handleFieldChange}
            value={fields.password.trim()}
          />
        </FormGroup>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        {(!pEmail || pEmail.length === 0) && <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>}
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={fields.password.trim()}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword.trim()}
          />
        </FormGroup>
        <FormGroup controlId="firstName" bsSize="large">
            <ControlLabel>First Name</ControlLabel>
            <FormControl
                type="text"
                onChange={handleFieldChange}
                value={fields.firstName}
            />
        </FormGroup>
        <FormGroup controlId="lastName" bsSize="large">
            <ControlLabel>Last Name</ControlLabel>
            <FormControl
                type="text"
                onChange={handleFieldChange}
                value={fields.lastName}
            />
        </FormGroup>
        <FormGroup controlId="site" bsSize="large">
            <ControlLabel>Trial Site Location</ControlLabel>
            {!loadingSites && <FormControl
                componentClass="select"
                placeholder="Select your location"
                onChange={handleFieldChange}
                value={fields.site}>
                <option key="none" value=""></option>
                  {[].concat(sites).sort((a,b) => a.siteName > b.siteName ? 1 : -1).map((site, index) => {
                    return (<option key={index} value={site.siteId}>{site.siteName}</option>)
                  })}
            </FormControl>}
        </FormGroup>
        {(!pPatientId || pPatientId.length === 0) && 
          <FormGroup controlId="patientId" bsSize="large">
            <ControlLabel>Patient ID (Leave blank if you don't have one)</ControlLabel>
            <FormControl
              type="text"
              onChange={handleFieldChange}
              value={fields.patientId}
            />
          </FormGroup>
        }
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
      {(newUser === null && rEmail === null) ? renderForm() : renderConfirmationForm()}
    </div>
  );
}