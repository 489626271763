import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Storage } from "aws-amplify";
import AxAPI from "../libs/axiosLib";
import { s3Upload } from "../libs/awsLib";
import { onError } from "../libs/errorLib";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import config from "../config";
import "./Images.css";

export default function Images() {
    const file = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const { authentication } = useAppContext();
    const [image, setImage] = useState(null);
    const [imageNote, setImageNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    async function loadImage() {
        var url = 'trialimages/getimagedata?ImageId=' + id;
        var res = await AxAPI.get(url);
        return res.data;
    }

    async function onLoad() {
      try {
        const image = await loadImage();
        const { imageNote, imageKey } = image;

        if (imageKey) {
          image.imageKeyURL = await Storage.get(imageKey);
        }
        setImageNote(imageNote);
        setImage(image);
        
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return true;
  }
  
  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }
  
  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function saveImage(updatedImage) {
    image.imageNote = imageNote;
    image.imageKey = updatedImage.imageKey;
    
    return AxAPI.put("trialimages/updateimagedata", image);
  }
  
  async function handleSubmit(event) {
    let imageKey;
  
    event.preventDefault();
  
    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
  
    setIsLoading(true);
  
    try {
      if (file.current) {
        var path = image.trialId + '/' + authentication.userDetails["custom:Location"] + '/';
        imageKey = await s3Upload(file.current, path);
      }
  
      await saveImage({
        imageNote,
        imageKey: imageKey || image.imageKey
      });
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteImage() {
    var url = 'trialimages/deleteimagedata?ImageId=' + id;
    // TODO: Need to remove the image from S3 too.
    return AxAPI.delete(url);
  }
  
  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteImage();
      history.push("/");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  return (
    <div className="Images">
      {image && (
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="imageNote">
            <FormControl
              value={imageNote}
              componentClass="textarea"
              onChange={e => setImageNote(e.target.value)}
            />
          </FormGroup>
          {image.imageKey && (
            <FormGroup>
              <ControlLabel>Attachment</ControlLabel>
              <FormControl.Static>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={image.imageKeyURL}
                >
                  {formatFilename(image.imageKey)}
                </a>
              </FormControl.Static>
            </FormGroup>
          )}
          <FormGroup controlId="file">
            {!image.imageKey && <ControlLabel>Attachment</ControlLabel>}
            <FormControl onChange={handleFileChange} type="file" />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            bsStyle="primary"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Save
          </LoaderButton>
          <LoaderButton
            block
            bsSize="large"
            bsStyle="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        </form>
      )}
    </div>
  );
}