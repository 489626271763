import React, { useRef, useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, Alert, Modal, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import { s3Upload } from "../libs/awsLib";
import { useAppContext } from "../libs/contextLib";
import AxAPI from "../libs/axiosLib";
import config from "../config";
import * as uuid from "uuid";
import "./NewImage.css";

export default function NewImage({passedUser}) {
  const file = useRef(null);
  const history = useHistory();
  const [imageNote, setImageNote] = useState("");
  const [trialId, setTrialId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [trials, setTrials] = useState([]);
  const [loadingTrials, setLoadingTrials] = useState(false);
  const [showUploadedAlert, setShowUploadedAlert] = useState(false);
  const [showUploadedModal, setShowUploadedModal] = useState(false);
  const { authentication } = useAppContext();

  useEffect(() => {
    async function onLoad() {
      setLoadingTrials(true);
      var location = (passedUser && passedUser.cognitoUserSub !== '') ? passedUser.location : authentication.userDetails["custom:Location"];
      var url = `trialdata/getsitetrials?siteid=${location}`;
      var trialList = await AxAPI.get(url);
      var recruitingTrials = trialList.data.results.filter(trial => trial.trialStatus === 'recruiting');
      setTrials(recruitingTrials);
      setLoadingTrials(false);
    }

    onLoad();
  }, [authentication.userDetails, passedUser]);
  
  function validateForm() {
    return trialId.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
    if(file.current) {
      if(file.current.type === "image/png" || file.current.type === "image/jpeg") {
        setFileSelected(true);
      }
      else {
        alert("Only image files (.png, .jpg, .jpeg) may be uploaded.");
        event.target.value = '';
      }
    }
    else {
      setFileSelected(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    
    setShowUploadedAlert(false);
    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }
  
    setIsLoading(true);
  
    try {
        var path = trialId + '/' + ((passedUser && passedUser.cognitoUserSub !== '') ? passedUser.location : authentication.userDetails["custom:Location"]) + '/';
        // TODO: We should be checking the result of this and notifying the user if it failed.
        const fileKey = await s3Upload(file.current, path);
        
        var imageData = {
            UserId: (passedUser && passedUser.cognitoUserSub !== '') ? passedUser.cognitoUserSub : authentication.userDetails.sub,
            ImageId: uuid.v1(),
            ImageKey: fileKey,
            ImageNote: imageNote,
            FirstName: (passedUser && passedUser.cognitoUserSub !== '') ? passedUser.givenName : authentication.userDetails.given_name,
            LastName: (passedUser && passedUser.cognitoUserSub !== '') ? passedUser.familyName : authentication.userDetails.family_name,
            TrialId: trialId,
            SiteId: (passedUser && passedUser.cognitoUserSub !== '') ? passedUser.location : authentication.userDetails["custom:Location"],
            PatientId: (passedUser && passedUser.cognitoUserSub !== '') ? passedUser.patientId : authentication.userDetails["custom:PatientId"]
        }
        // TODO: We should be checking the result of this and notifying the user if it failed.
        await createImageData(imageData);
        
        // If we're doing this from the admin page, we want to show a non modal alert
        if(passedUser) {
          setShowUploadedAlert(true);
          setIsLoading(false);
        } else {
          // If this is the user uploading
          setShowUploadedModal(true);
        }
      
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function createImageData(imageData) {
    return AxAPI.post("trialimages/addimagedata", imageData);
  }

  function handleCloseUploadedModal() {
    setIsLoading(false);
    setShowUploadedModal(false);
    history.push("/");
  }

  return (
    <Fragment>
      <div className="NewImage">
        {!loadingTrials && 
        <form id="imageUpload" onSubmit={handleSubmit}>
          <FormGroup controlId="trial" bsSize="large">
            <ControlLabel>Select Trial</ControlLabel>
            <FormControl
                componentClass="select"
                placeholder="Select your trial"
                onChange={e => setTrialId(e.target.value)}
                value={trialId}>
                <option key="none" value=""></option>
                {[].concat(trials).sort((a,b) => a.displayOrder > b.displayOrder ? 1 : -1).map((trial, index) => {
                  return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                })}
            </FormControl>
          </FormGroup>
          {trialId !== '' &&
            <Fragment>
              <FormGroup controlId="imageNote">
              <ControlLabel>Add Notes (200 characters max)</ControlLabel>
              <FormControl
                value={imageNote}
                componentClass="textarea"
                maxLength={200}
                onChange={e => setImageNote(e.target.value)}
              />
            </FormGroup>
            <FormGroup controlId="file">
              <ControlLabel>Select Image</ControlLabel>
              <FormControl onChange={handleFileChange} type="file" />
            </FormGroup>
            <LoaderButton
              block
              type="submit"
              bsSize="large"
              bsStyle="primary"
              isLoading={isLoading}
              disabled={!validateForm() || !fileSelected}
            >
              Upload
            </LoaderButton>
          </Fragment>}
        </form>}
        {showUploadedAlert && 
          <Fragment>
            <Alert bsStyle="info">Image has been uploaded</Alert>
          </Fragment>
        }
      </div>
      <Modal
        dialogClassName="modalDialog"
        bsSize="large"
        show={showUploadedModal}
        onHide={handleCloseUploadedModal}
      >
      <Modal.Header closeButton>
          <Modal.Title>Image upload successful!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Your image was uploaded, thank you!</p>The clinical study team will assess it soon.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseUploadedModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}