import React, { useState, Fragment } from "react";
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Radio,
    Table,
    Button,
    Modal,
    Tab,
    Row,
    Col,
    Nav,
    NavItem
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./ContactManagementModal.css";
import statuses from '../constants/statuses';

export default function ContactManagementModal({disqualificationReasons, patientToEdit, isLoading, handleUpdatePatientTrial, handleRecordNewContact, handleCloseDetailsModal}) {
    const [newContactFields, handleNewContactFieldChange] = useFormFields({
        contactType: "",
        contactDetails: ""
      });
    const [patientTrialFields, handlePatientTrialFieldChange] = useFormFields({
        ...patientToEdit.trialData
    });

    const [contactsSortHeader, setContactsSortHeader] = useState('dateTime');
    const [contactsSortDir, setContactsSortDir] = useState(1);

    function validateNewContactForm() {
        return newContactFields.contactType.length > 0 && newContactFields.contactDetails.length > 0;
    }

    function validatePatientDetailsForm() {
        if(patientTrialFields.status === 'Disqualified' && (!patientTrialFields.disqualificationReason || (patientTrialFields.disqualificationReason === 'other' && !patientTrialFields.disqualificationReasonOther))){
            return false;
        }
        return true;
    }

    function changeContactsSort(headerName) {
        var newSortDir = headerName === contactsSortHeader ? -contactsSortDir : 1;
        setContactsSortHeader(headerName);
        setContactsSortDir(newSortDir);
    }

    function getContactsArray() {
        var contactsArray;
        switch(contactsSortHeader)
        {
            case 'dateTime':
                contactsArray = [].concat(patientToEdit.contactData).sort((a,b) => a.contactDateTime > b.contactDateTime ? contactsSortDir : -contactsSortDir);
                break;
            case 'type':
                contactsArray = [].concat(patientToEdit.contactData).sort((a,b) => a.contactType > b.contactType ? contactsSortDir : -contactsSortDir);
                break;
            case 'status':
                contactsArray = [].concat(patientToEdit.contactData).sort((a,b) => a.contactStatus > b.contactStatus ? contactsSortDir : -contactsSortDir);
                break
            default:
                break;
        }

        return contactsArray;
    }

    const icfSent = patientTrialFields.icfSent === true || patientTrialFields.icfSent === "true";
    const screeningBooked = patientTrialFields.screeningBooked === true || patientTrialFields.screeningBooked === "true";
    
    async function handlePatientDetailsSubmit(event) {
        event.preventDefault();
        handleUpdatePatientTrial(patientTrialFields);
    }

    async function handlePatientContactSubmit(event) {
        event.preventDefault();
        handleRecordNewContact(newContactFields);
    }

    return (
        <Modal
            bsSize="large"
            show={true}
            onHide={handleCloseDetailsModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                <div>
                    <p>
                        <b>Patient Name: </b>{patientToEdit.userData.givenName + ' ' + patientToEdit.userData.familyName + ' '}
                        <b>Patient ID: </b>{patientToEdit.userData.patientId}
                    </p>
                    <p>
                        <b>Email: </b>{patientToEdit.userData.email + ' '}
                        <b>Phone: </b>{patientToEdit.userData.phoneNumber}
                    </p>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Tab.Container generateChildId={(eventKey, type) => `${'contactMenu'}-${type}-${eventKey}`} defaultActiveKey={1}>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <Nav bsStyle="tabs">
                                    <NavItem eventKey={1}>Patient Info</NavItem>
                                    <NavItem eventKey={2}>Contact Details</NavItem>
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content animation>
                                    <Tab.Pane eventKey={1}>
                                        <Fragment>
                                            <form className="frmPatientInfo" onSubmit={handlePatientDetailsSubmit}>
                                                <FormGroup controlId="status" bsSize="large">
                                                    <ControlLabel>Status</ControlLabel>
                                                    <FormControl
                                                        componentClass="select"
                                                        onChange={handlePatientTrialFieldChange}
                                                        value={patientTrialFields.status}>
                                                            {statuses.map((reason, index) => {
                                                                return (<option key={index} value={reason.key}>{reason.text}</option>)
                                                            })}
                                                    </FormControl>
                                                </FormGroup>
                                                {patientTrialFields.status === 'Disqualified' && (
                                                    <Fragment>
                                                        <FormGroup controlId="disqualificationReason" bsSize="large">
                                                            <ControlLabel>Reason For Disqualification</ControlLabel>
                                                            <FormControl
                                                                componentClass="select"
                                                                placeholder="Select reason"
                                                                onChange={handlePatientTrialFieldChange}
                                                                value={patientTrialFields.disqualificationReason}>
                                                                <option key="none" value=""></option>
                                                                    {[].concat(disqualificationReasons).sort((a,b) => a.displayOrder > b.displayOrder ? 1 : -1).map((reason, index) => {
                                                                    return (<option key={index} value={reason.disqReasonText}>{reason.disqReasonText}</option>)
                                                                    })}
                                                                    <option key="other" value="other">Other</option>
                                                            </FormControl>
                                                        </FormGroup>
                                                        {patientTrialFields.disqualificationReason === 'other' && (
                                                            <FormGroup controlId="disqualificationReasonOther" bsSize="large">
                                                                <FormControl
                                                                    componentClass="input"
                                                                    placeholder="Enter other reason"
                                                                    onChange={handlePatientTrialFieldChange}
                                                                    value={patientTrialFields.disqualificationReasonOther}>
                                                                </FormControl>
                                                            </FormGroup>
                                                        )}
                                                    </Fragment>
                                                )}
                                                <FormGroup controlId="icfSent" bsSize="large">
                                                    <ControlLabel>ICF Sent?</ControlLabel>
                                                    <Radio
                                                        id="icfSent"
                                                        value={true}
                                                        checked={icfSent}
                                                        onChange={handlePatientTrialFieldChange}
                                                    >
                                                        Yes
                                                    </Radio>{' '}
                                                    <Radio
                                                        id="icfSent"
                                                        value={false}
                                                        checked={!icfSent}
                                                        onChange={handlePatientTrialFieldChange}
                                                    >
                                                        No
                                                    </Radio>
                                                </FormGroup>

                                                <FormGroup controlId="randomisationNumber" bsSize="large">
                                                    <ControlLabel>Randomisation number</ControlLabel>
                                                    <FormControl
                                                        componentClass="input"
                                                        placeholder="Enter randomisation number"
                                                        onChange={handlePatientTrialFieldChange}
                                                        value={patientTrialFields.randomisationNumber}>
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup controlId="screeningBooked" bsSize="large">
                                                    <ControlLabel>Screening booked?</ControlLabel>
                                                    <Radio
                                                        id="screeningBooked"
                                                        value={true}
                                                        checked={screeningBooked}
                                                        onChange={handlePatientTrialFieldChange}
                                                        >
                                                        Yes
                                                    </Radio>{' '}
                                                    <Radio
                                                        id="screeningBooked"
                                                        value={false}
                                                        checked={!screeningBooked}
                                                        onChange={handlePatientTrialFieldChange}
                                                        >
                                                        No
                                                    </Radio>
                                                </FormGroup>
                                                {screeningBooked && (
                                                    <Fragment>
                                                        <FormGroup controlId="screeningNumber" bsSize="large">
                                                            <FormControl
                                                                componentClass="input"
                                                                placeholder="Enter screening number"
                                                                onChange={handlePatientTrialFieldChange}
                                                                value={patientTrialFields.screeningNumber}>
                                                            </FormControl>
                                                        </FormGroup>
                                                        <FormGroup controlId="screeningDate" bsSize="large">
                                                            <FormControl
                                                                componentClass="input"
                                                                placeholder="Enter screening date"
                                                                onChange={handlePatientTrialFieldChange}
                                                                value={patientTrialFields.screeningDate}>
                                                            </FormControl>
                                                        </FormGroup>
                                                        <FormGroup controlId="screeningDatePart2" bsSize="large">
                                                            <FormControl
                                                                componentClass="input"
                                                                placeholder="Enter screening date 2"
                                                                onChange={handlePatientTrialFieldChange}
                                                                value={patientTrialFields.screeningDatePart2}>
                                                            </FormControl>
                                                        </FormGroup>
                                                    </Fragment>
                                                )}
                                                <LoaderButton
                                                    className="btnSmall"
                                                    type="submit"
                                                    bsSize="large"
                                                    bsStyle="success"
                                                    isLoading={isLoading}
                                                    disabled={!validatePatientDetailsForm()}
                                                >
                                                    Save details
                                                </LoaderButton>
                                            </form>
                                        </Fragment>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={2}>
                                        <Fragment>
                                            <div>
                                                <form className="frmPanel" onSubmit={handlePatientContactSubmit}>
                                                    <FormGroup controlId="contactType" bsSize="large">
                                                        <ControlLabel>Contact Type</ControlLabel>
                                                        <FormControl
                                                            componentClass="select"
                                                            placeholder="Contact Type"
                                                            onChange={handleNewContactFieldChange}
                                                            value={newContactFields.contactType}>
                                                            <option value=""></option>
                                                            <option value="email">Email</option>
                                                            <option value="phone">Phone</option>
                                                            <option value="sms">SMS</option>
                                                        </FormControl>
                                                    </FormGroup>
                                                    <FormGroup controlId="contactDetails" bsSize="large">
                                                        <ControlLabel>Contact Details</ControlLabel>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            className="fixedtextarea"
                                                            value={newContactFields.contactDetails}
                                                            onChange={handleNewContactFieldChange}>
                                                        </FormControl>
                                                    </FormGroup>
                                                    <LoaderButton
                                                        className="btnSmall"
                                                        type="submit"
                                                        bsSize="large"
                                                        bsStyle="success"
                                                        isLoading={isLoading}
                                                        disabled={!validateNewContactForm()}
                                                    >
                                                        Save Contact
                                                </LoaderButton>
                                                </form>
                                            </div>
                                            <hr />
                                            <ControlLabel>Previous Contact Attempts</ControlLabel>
                                            <div className="scrollableDiv">
                                                <Table responsive striped bordered condensed hover>
                                                    <thead>
                                                        <tr key="header">
                                                            <th className="sortableTableHeader" onClick={e => changeContactsSort("dateTime")}>Date/Time</th>
                                                            <th className="sortableTableHeader" onClick={e => changeContactsSort("type")}>Type</th>
                                                            <th>Details</th>
                                                            <th>Author</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {getContactsArray().map((contact, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{contact.contactDateTime}</td>
                                                                    <td>{contact.contactType}</td>
                                                                    <td>{contact.contactDetails}</td>
                                                                    <td>{contact.author}</td>
                                                                </tr>)
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Fragment>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCloseDetailsModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}