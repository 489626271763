import React, { useState, useEffect, Fragment } from "react";
import { PageHeader, ListGroup, ListGroupItem, Glyphicon } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import AdminMenuPage from "./AdminMenuPage";
import SiteUserMenuPage from "./SiteUserMenuPage";
import AxAPI from "../libs/axiosLib";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";

export default function Home() {
  const history = useHistory();
  const [images, setImages] = useState([]);
  const { authentication } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if(!authentication.isAuthenticated) {
        return;
      }
  
      try {
        var url = 'trialimages/getallimagedata?UserId=' + authentication.userDetails.sub;
        var res = await AxAPI.get(url);
        setImages(res.data);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [history, authentication.userDetails.sub, authentication.isAuthenticated]);
  
  function renderImagesList(images) {
    return (
      <Fragment>
        <LinkContainer key="new" to={`/images/new/${authentication.userDetails.email}`}>
            <h4>
              <b>{"\uFF0B"}</b> Create a new image
            </h4>
        </LinkContainer>
        {
          [].concat(images).sort((a,b) => a.uploadDateTime > b.uploadDateTime ? -1 : 1).map((image, i) =>
          (
            <LinkContainer key={image.imageId} to={`/images/${image.imageId}`}>
              <ListGroupItem header={image.imageNote.trim().split("\n")[0]}>
                {"Uploaded: " + image.uploadDateTime}
              </ListGroupItem>
            </LinkContainer>
          ))
        }
      </Fragment>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Evrima Technologies</h1>
        <p>Clinical Trials Informatics Hub</p>
      </div>
    );
  }

  function renderImages() {
    return (
      <div className="images">
        <PageHeader>Your Images</PageHeader>
        {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
        <ListGroup>
          {!isLoading && renderImagesList(images)}
        </ListGroup>
      </div>
    );
  }

  // TODO: Add additional nav item for sponsor.

  return (
    <div className="Home">
        { authentication.isAuthenticated ? authentication.isAdmin ? <AdminMenuPage /> : authentication.isSiteUser ? <SiteUserMenuPage impersonateSiteId={''} /> : renderImages() : renderLander() }
    </div>
  );
}