import React from "react";
import {
    Tab,
    Row,
    Col,
    Nav,
    NavItem
} from "react-bootstrap";
import SiteUserPanel from "./SiteUserPanel";
import ContactManagementPanel from "./ContactManagementPanel";

export default function SiteUserMenuPage({ impersonateSiteId }) {
    
    return (
        <div>
            <Tab.Container generateChildId={(eventKey, type) => `${'siteMenu'}-${type}-${eventKey}`} defaultActiveKey={1}>
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="tabs">
                            <NavItem eventKey={1}>Assess Images</NavItem>
                            <NavItem eventKey={2}>Contact Management</NavItem>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content animation>
                            <Tab.Pane eventKey={1}><SiteUserPanel impersonateSiteId={impersonateSiteId} /></Tab.Pane>
                            <Tab.Pane eventKey={2}><ContactManagementPanel impersonateSiteId={impersonateSiteId} /></Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}