import React from "react";
//import { Button, Glyphicon } from "react-bootstrap";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <p>&copy; Evrima Technologies Pty Ltd</p>
        <p><a target="_blank" rel="noreferrer" href="https://evrima.com.au/privacy">Privacy Policy</a></p>
        <p><a href="mailto:enquiries@evrima.com.au">Contact Us @ enquiries@evrima.com.au</a></p>
      </div>
    </div>
  );
}