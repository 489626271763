import axios from 'axios';
import config from '../config';

export default axios.create({
    baseURL: config.axios.BASE_ENDPOINT_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'x-api-key': config.apiGateway.KEY
    }
});