import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import AxAPI from "../libs/axiosLib";
import {FormGroup,
  FormControl,
  ControlLabel,
  ListGroup,
  ListGroupItem,
  PageHeader,
  Panel,
  Image,
  Checkbox,
  Modal,
  Button,
  Glyphicon,
  Radio,
  Alert} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import "./SiteUserPanel.css";

export default function SiteUserPanel({ impersonateSiteId }) {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trialId, setTrialId] = useState("");
  const [includeApproved, setIncludeApproved] = useState(true);
  const [includeDisqualified, setIncludeDisqualified] = useState(true);
  const [modalImage, setModalImage] = useState();
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalDisq, setShowModalDisq] = useState(false);
  const [trials, setTrials] = useState([]);
  const [currentImageId, setCurrentImageId] = useState(null);
  const [imageDisqReason, setImageDisqReason] = useState('');
  const [imageDisqReasons, setImageDisqReasons] = useState([]);
  const [loadingDisqReasons, setLoadingDisqReasons] = useState(false);
  const [otherImageDisq, setOtherImageDisq] = useState('');
  const [sortType, setSortType] = useState('date');
  const [sortDir, setSortDir] = useState('asc');
  const [showNowImagesAlert, setShowNowImagesAlert] = useState(false);
  const { authentication } = useAppContext();

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      var url = `trialdata/getsitetrials?siteid=${impersonateSiteId === '' ? authentication.userDetails["custom:Location"] : impersonateSiteId}`
      var result = await AxAPI.get(url);
      setTrials(result.data.results);
      setImages([]);
      setShowNowImagesAlert(false);
      setIsLoading(false);
    }
    
    onLoad();
  }, [authentication.userDetails, impersonateSiteId]);
  
  async function getDisqReasons() {
    setLoadingDisqReasons(true);
    var url = `trialData/getimagedisqreasons?trialid=${trialId}`
    var result = await AxAPI.get(url);
    setImageDisqReasons(result.data);
    setLoadingDisqReasons(false);
  }

  function validateForm() {
    return trialId.length > 0;
  }

  async function loadImages() {
    setIsLoading(true);
    setShowNowImagesAlert(false);
    var impersonateParam = impersonateSiteId === '' ? '' : `&impersonatesite=${impersonateSiteId}`;
    var url = `trialimages/gettrialimages?trialid=${trialId}&usersub=${authentication.userDetails.sub}&statuses=pending${includeApproved ? ',approved' : ''}${includeDisqualified ? ',disqualified' : ''}${impersonateParam}`;
    var res = await AxAPI.get(url);
    setImages(res.data.results);
    setIsLoading(false);
    if(res.data.results.length === 0) {
      setShowNowImagesAlert(true);
    }
  }

  async function handleApprove(imageId) {
    setShowModalImage(false);
    setIsLoading(true);
    await approveImage(imageId);
    await loadImages();
    setIsLoading(false);
  }

  async function handleDisqualify() {
    setShowModalDisq(false);
    setShowModalImage(false);
    setIsLoading(true);
    await disqualifyImage();
    await loadImages();

    setCurrentImageId('');
    setImageDisqReason('');
    setOtherImageDisq('');
    setIsLoading(false);
  }

  async function handleResetStatus(imageId) {
    setIsLoading(true);
    const confirmed = window.confirm(
      "Are you sure you wish to reset the status of this image?"
    );
  
    if (!confirmed) {
      return;
    }
    setShowModalImage(false);
    await resetImageStatus(imageId);
    await loadImages();

    setIsLoading(false);
  }

  async function handleRecordContact() {
    window.alert('Coming soon!');
  }

  async function approveImage(imageId) {
    var img = images.find(item => item.imageId === imageId);
    img.updatedUserId = authentication.userDetails.sub;
    img.status = 'approved';
    img.statusReason = '';

    var resp = await AxAPI.put("trialimages/updateimagedata", img);

    return resp;
  }

  async function disqualifyImage() {
    var img = images.find(item => item.imageId === currentImageId);
    img.updatedUserId = authentication.userDetails.sub;
    img.status = 'disqualified';
    img.statusReason = imageDisqReason;
    img.statusReasonOther = otherImageDisq;

    var resp = await AxAPI.put("trialimages/updateimagedata", img);

    return resp;
  }

  async function resetImageStatus(imageId) {
    var img = images.find(item => item.imageId === imageId);
    img.updatedUserId = authentication.userDetails.sub;
    img.status = 'pendingAssessment';
    img.statusReason = '';

    var resp = await AxAPI.put("trialimages/updateimagedata", img);
    
    return resp;
  }

  async function getImageForModal(modalImageId) {
    var img = images.find(item => item.imageId === modalImageId);
    if(img)
    {
      img.imageUrl = await Storage.get(img.imageKey);
      setModalImage(img);
    }    
  }

  async function handleShowModalImage(imageId) {
    await getImageForModal(imageId);
    setShowModalImage(true);
  }

  function handleCloseModalImage() {
    setShowModalImage(false);
  }

  async function handleShowModalDisq(imageId) {
    setCurrentImageId(imageId);
    setShowModalDisq(true);
  }

  function handleCloseModalDisq() {
    setCurrentImageId(null);
    setShowModalDisq(false);
    setCurrentImageId('');
    setImageDisqReason('');
  }

  function renderImagesList(images) {
    var val1;
    var val2;
    if(sortDir === 'asc') {
      val1 = 1;
      val2 = -1;
    }

    if(sortDir === 'desc') {
      val1 = -1;
      val2 = 1;
    }

    var imgArray = sortType === 'date' ? [].concat(images).sort((a,b) => a.uploadDateTime > b.uploadDateTime ? val1 : val2) : [].concat(images).sort((a,b) => a.lastName > b.lastName ? val1 : val2);
    return [{}].concat(imgArray).map((image, i) =>
      i !== 0 ? (
        <div className="flexrow" key={image.imageId}>
          <div className="imageflexrow">
            <div>
              <Button
                className="btnSmall"
                bsSize="small"
                bsStyle="info"
                value={image.imageId}
                onClick={e => handleShowModalImage(e.target.value)}>
                  View Image
              </Button>
            </div>
            <div>
              <ListGroupItem
                onClick={() => handleShowModalImage(image.imageId)}
                bsStyle={image.status === "approved" ? "success" : image.status === "disqualified" ? "warning" : null}
              >
                <div className="notesHeader">
                  <span className="heavyText">{image.firstName + ' ' + image.lastName}</span>{' - Uploaded: ' + image.uploadDateTime}
                </div>
                <div className="notes">
                  {image.imageNote.trim().split("\n")[0]}
                </div>
                {image.status === 'disqualified' && <div className="notes">
                  <span className="heavyText">Image DQ Reason:</span> {image.statusReason !== 'other' ? image.statusReason : 'Other: ' + image.statusReasonOther}
                </div>}
              </ListGroupItem>
            </div>
          </div>
          {renderAssessButtons(image, '', false)}
        </div>
      ) : ('')
    );
  }

  async function handleLoadImages(event) {
    event.preventDefault();
    await loadImages();
    await getDisqReasons();
  }

  function renderImages() {
    return (
      <div className="images">
          <ListGroup>
            {(!isLoading && !loadingDisqReasons) && renderImagesList(images)}
          </ListGroup>
        </div>
    );
  }

  function handleSortChange(sortBy) {
    if(sortBy === 'date' || sortBy === 'name') {
      setSortType(sortBy);
    }

    if(sortBy === 'asc' || sortBy === 'desc') {
      setSortDir(sortBy);
    }
  }

  function renderAssessButtons(img, styleType, contactButton) {
    return(
      <div className={styleType}>
        <Button
          className="btnSmall"
          type="submit"
          bsSize="small"
          bsStyle="success"
          disabled={img.status === 'approved'}
          value={img.imageId}
          onClick={e => handleApprove(e.target.value)}>
            Approve
        </Button>
        <Button
          className="btnSmall"
          type="submit"
          bsSize="small"
          bsStyle="danger"
          disabled={img.status === 'disqualified'}
          value={img.imageId}
          onClick={e => handleShowModalDisq(e.target.value)}>
            Disqualify
        </Button>
        <Button
          className="btnSmall"
          type="submit"
          bsSize="small"
          bsStyle="warning"
          disabled={img.status === 'pendingAssessment'}
          value={img.imageId}
          onClick={e => handleResetStatus(e.target.value)}>
            Reset Status
        </Button>
        {contactButton && <Button
              className="btnSmall"
              type="submit"
              bsSize="small"
              bsStyle="info"
              value={img.imageId}
              onClick={e => handleRecordContact(e.target.value)}>
                Record Contact
            </Button>}
      </div>
    );
  }

  return (
    <div className="SiteUserPanel">
        <PageHeader>Assess Trial Images</PageHeader>
        <Panel>
            <Panel.Heading>Trial Images</Panel.Heading>
            {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
            {!isLoading && <form className="frmPanel" onSubmit={handleLoadImages}>
                <FormGroup className="flexrow" controlId="trialName" bsSize="large">
                    <div className="flexCol">
                        <ControlLabel>Trial Name</ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="Select a trial"
                          onChange={e => setTrialId(e.target.value)}
                          value={trialId}>
                          <option key="none" value=""></option>
                          {[].concat(trials).sort((a,b) => a.displayOrder > b.displayOrder ? 1 : -1).map((trial, index) => {
                            return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                          })}
                        </FormControl>
                    </div>
                    <div className="flexcol">
                      <ControlLabel>
                        Include Statuses:
                      </ControlLabel>
                      <div>
                        <Checkbox
                          inline
                          checked={includeApproved}
                          onChange={e => setIncludeApproved(e.target.checked)}
                          >Approved
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          inline
                          checked={includeDisqualified}
                          onChange={e => setIncludeDisqualified(e.target.checked)}
                          >Disqualified
                        </Checkbox>
                      </div>
                    </div>
                    <div className="flexcol">
                      <LoaderButton
                        className="btnSmall"
                        type="submit"
                        bsSize="large"
                        bsStyle="success"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                        >
                        View Images
                      </LoaderButton>
                    </div>
                </FormGroup>
            </form>}
        </Panel>
        {showNowImagesAlert && <Alert bsStyle="warning">
          There are no images to show for that trial.
        </Alert>}
        {images.length > 0 && <div>
          <Panel>
            <Panel.Heading>Sorting</Panel.Heading>
            <FormGroup className="flexRow2">
              <Radio
                name="sortType"
                value="date"
                checked={sortType === "date"}
                onChange={e => handleSortChange(e.target.value)}
                inline>
                Sort by date
              </Radio>{' '}
              <Radio
                name="sortType"
                value="name"
                checked={sortType === "name"}
                onChange={e => handleSortChange(e.target.value)}
                inline>
                Sort by name
              </Radio>
              <Radio
                name="sortDirection"
                value="asc"
                checked={sortDir === "asc"}
                onChange={e => handleSortChange(e.target.value)}
                inline>
                Ascending
              </Radio>{' '}
              <Radio
                name="sortDirection"
                value="desc"
                checked={sortDir === "desc"}
                onChange={e => handleSortChange(e.target.value)}
                inline>
                Descending
              </Radio>
            </FormGroup>
          </Panel>
        </div>}
        {images.length > 0 && renderImages() }
        <Modal
          dialogClassName="modalDialog"
          bsSize="large"
          show={showModalImage}
          onHide={handleCloseModalImage}
        >
        <Modal.Header closeButton>
            <Modal.Title>Assess Image</Modal.Title>
          </Modal.Header>
          {modalImage && <Modal.Body>
            <Image className="modalImage" src={modalImage.imageUrl} responsive />
            <div>
              <span className="heavyText">Patient Name: </span>{modalImage.firstName + ' ' + modalImage.lastName}
            </div>
            <div>
              <span className="heavyText">Uploaded: </span>{modalImage.uploadDateTime}
            </div>
            <div>
              <span className="heavyText">Patient Notes: </span>{modalImage.imageNote.trim()}
            </div>
            {modalImage.status === 'disqualified' && <div>
              <span className="heavyText">Image DQ Reason: </span>{modalImage.statusReason !== 'other' ? modalImage.statusReason : 'Other: ' + modalImage.statusReasonOther}
            </div>}
            <hr />
            {renderAssessButtons(modalImage, 'modalFlexRow')}
          </Modal.Body>}
          <Modal.Footer>
            <Button onClick={handleCloseModalImage}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName="modalDialog"
          bsSize="large"
          show={showModalDisq}
          onHide={handleCloseModalDisq}
        >
        <Modal.Header closeButton>
            <Modal.Title>Disqualify Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modalFlexRow">
              <div className="modalFlexCol">
                <FormControl
                  componentClass="select"
                  bsSize="large"
                  placeholder="Disqualification reason"
                  onChange={e => setImageDisqReason(e.target.value)}
                  value={imageDisqReason}>
                  <option key="none" value=""></option>
                    {[].concat(imageDisqReasons).sort((a,b) => a.displayOrder > b.displayOrder ? 1 : -1).map((reason, index) => {
                      return (<option key={index} value={reason.disqReasonText}>{reason.disqReasonText}</option>)
                    })}
                    <option key="other" value="other">Other</option>
                </FormControl>
                {imageDisqReason==='other' && <FormControl
                  type="text"
                  bsSize="large"
                  value={otherImageDisq}
                  onChange={e => setOtherImageDisq(e.target.value)}>
                </FormControl>}
              </div>
              <div>
                <Button
                  className="btnSmall"
                  type="submit"
                  bsSize="small"
                  bsStyle="danger"
                  disabled={imageDisqReason === '' || (imageDisqReason==='other' && otherImageDisq === '')}
                  onClick={handleDisqualify}>
                    Disqualify
                </Button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseModalDisq}>Close</Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}