import React, { useState, useEffect } from "react";
import {
    Tab,
    Row,
    Col,
    Nav,
    NavItem,
    FormGroup,
    FormControl,
    ControlLabel
} from "react-bootstrap";
import AdminPanel from "./AdminPanel";
import AdminReports from "./AdminReports";
import SiteUserMenuPage from "./SiteUserMenuPage";
import { onError } from "../libs/errorLib";
import AxAPI from "../libs/axiosLib";

export default function AdminMenuPage() {
    const [sites, setSites] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [impersonateSiteId, setImpersonateSiteId] = useState('');
    
    useEffect(() => {
        async function onLoad() {
          try {
            await loadData();
          } catch (e) {
            onError(e);
          }
        }
             
        onLoad();
    }, []);

    async function loadData() {
        setIsLoading(true);
        
        var url = "trialdata/getsites?includeinactive=true";
        var sitesResult = await AxAPI.get(url);
        setSites(sitesResult.data);

        setIsLoading(false);
    }

    return (
        <div>
            <Tab.Container generateChildId={(eventKey, type) => `${'adminMenu'}-${type}-${eventKey}`} defaultActiveKey={1}>
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="tabs">
                            <NavItem eventKey={1}>Admin</NavItem>
                            <NavItem eventKey={2}>Site User</NavItem>
                            <NavItem eventKey={3}>Admin Reports</NavItem>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content animation>
                            <Tab.Pane eventKey={1}><AdminPanel /></Tab.Pane>
                            <Tab.Pane eventKey={2}>
                                <FormGroup controlId="site" bsSize="large">
                                    <ControlLabel>Trial Site Location</ControlLabel>
                                    {!isLoading && <FormControl
                                        componentClass="select"
                                        placeholder="Select your location"
                                        onChange={e => setImpersonateSiteId(e.target.value)}
                                        value={ impersonateSiteId }>
                                        <option key="none" value=""></option>
                                        {[].concat(sites).sort((a,b) => a.siteName > b.siteName ? 1 : -1).map((site, index) => {
                                            return (<option key={index} value={site.siteId}>{site.siteName}</option>)
                                        })}
                                    </FormControl>}
                                </FormGroup>
                                {impersonateSiteId &&
                                    <SiteUserMenuPage impersonateSiteId={impersonateSiteId} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey={3}><AdminReports /></Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}