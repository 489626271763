import React, { useState, useEffect, Fragment } from "react";
import { Auth } from "aws-amplify";
import { AppContext } from "./libs/contextLib";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { Nav, Navbar, NavItem, Image } from "react-bootstrap";
import { buildAuthObject } from "./libs/authLib";
import Footer from './components/Footer';
import Logo from './assets/img/Evrima_LOGO_High.png';
import Routes from "./Routes";
import "./App.css";

function App() {
  const history = useHistory();
  const [authentication, setAuthentication] = useState(buildAuthObject(null, false));
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      var session = await Auth.currentSession();
      setAuthentication(buildAuthObject(session));
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
  
    setAuthentication(buildAuthObject(null, false));
  
    history.push("/login");
  }

  return (
    <Fragment>
      <Navbar fluid collapseOnSelect>
        <div className="container">
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <Image src={Logo} alt="Logo" className="navbar-brand" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {authentication.isAuthenticated ? (
                <Fragment>
                  <NavItem onClick={handleLogout}>Logout</NavItem>
                </Fragment>
              ) : (
                <Fragment>
                  <LinkContainer to="/signup">
                    <NavItem>Signup</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </Fragment> 
            )}
              
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {!isAuthenticating && (
        <div className="App container">
          {authentication.isAuthenticated && <div className="flexRight"><span className="heavyText">Logged in as:</span>&nbsp;{authentication.userDetails.email}</div>}        
          <AppContext.Provider
            value={{ authentication, setAuthentication }}
          >
            <Routes />
          </AppContext.Provider>
        </div>
      )}
      <Footer/>
    </Fragment>
  );
}

export default App;