import React, { useState, Fragment } from "react";
import { Auth } from "aws-amplify";
import AxAPI from "../libs/axiosLib";
import { FormGroup, FormControl, ControlLabel, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { buildAuthObject } from "../libs/authLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { HelpBlock } from "react-bootstrap";
import "./Login.css";

export default function Login() {
  const history = useHistory();
  const { setAuthentication } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordForm, setNewPasswordForm] = useState(false);
  const [showPasswordResetAlert, setShowPasswordResetAlert] = useState(false);
  const [showNoUserAlert, setShowNoUserAlert] = useState(false);
  const [showResetFailedAlert, setShowResetFailedAlert] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    newPassword: "",
    newPassword2: ""
  });

  function validateEmail() {
    return (fields.email.length > 0 && fields.email.indexOf('@') !== -1);
  }

  function validateLoginForm() {
    return validateEmail() && fields.password.trim().length > 0;
  }

  function validateNewPasswordForm() {
    return validateEmail() && fields.newPassword.trim().length >= 8 && fields.newPassword.trim() === fields.newPassword2.trim() && fields.password.trim().length > 0;
  }

  async function handleSubmitLogin(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      var response = await Auth.signIn(fields.email, fields.password.trim());
      if(response.challengeName) {
        if(response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setNewPasswordForm(true);
        }
        setIsLoading(false);
      } else {
        setAuthentication(buildAuthObject(response.signInUserSession));
      }
    } catch (e) {
      setIsLoading(false);
      if(e.code === "UserNotConfirmedException") {
        history.push(`/signup?rEmail=${fields.email}`);
      } else {
        onError(e);
      }
    }
  }

  async function handlePasswordReset(event) {
    event.preventDefault();

    resetAlerts();
    setIsLoading(true);

    var pwdResetReq = {
      UserId: 'NotLoggedIn',
      email: fields.email
    };
  
    var resp = await AxAPI.post("users/resetpassword", pwdResetReq);
    console.log(resp);

    if(!resp.data.successful) {
      if(resp.data.results === 'No user with that email address.') {
        setShowNoUserAlert(true);
      } else {
        setShowResetFailedAlert(true);
      }
    } else {
      setShowPasswordResetAlert(true);
    }
    
    setIsLoading(false);
  }

  function resetAlerts() {
    setShowNoUserAlert(false);
    setShowPasswordResetAlert(false);
    setShowResetFailedAlert(false);
  }

  async function handleSubmitNewPassword(event) {
    event.preventDefault();
    setIsLoading(true);
    Auth.signIn(fields.email, fields.password.trim()).then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(
            user,
            fields.newPassword.trim(),
        ).then(user => {
            // at this time the user is logged in if no MFA required
            setAuthentication(buildAuthObject(user.signInUserSession));
        }).catch(e => {
          window.alert(e.message);
        });
      } else {
          // other situations
      }
    }).catch(e => {
      console.log(e);
    });
    setIsLoading(false);
  }

  function renderNewPasswordForm() {
    return (
      <div className="Login">
        <form onSubmit={handleSubmitNewPassword}>
        <HelpBlock>You must enter a new password.</HelpBlock>
          <FormGroup controlId="newEmail" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
                autoFocus
                type="email"
                bsSize="large"
                value={fields.email}
                onChange={handleFieldChange}
              />
          </FormGroup>
          <FormGroup controlId="existPassword" bsSize="large">
            <ControlLabel>Existing Password</ControlLabel>
            <FormControl
              type="password"
              bsSize="large"
              value={fields.password.trim()}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="newPassword" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              type="password"
              bsSize="large"
              value={fields.newPassword.trim()}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="newPassword2" bsSize="large">
            <ControlLabel>Confirm Password</ControlLabel>
            <FormControl
              type="password"
              bsSize="large"
              value={fields.newPassword2.trim()}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateNewPasswordForm()}
          >
            Submit
          </LoaderButton>
        </form>
      </div>
    );
  }

  function renderLoginForm() {
    return (
      <div className="Login">
        <form onSubmit={handleSubmitLogin}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              type="password"
              value={fields.password.trim()}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateLoginForm()}
          >
            Login
          </LoaderButton>
        </form>
        <div className="passwordReset">
          <form onSubmit={handlePasswordReset}>
            <FormGroup controlId="passwordReset" bsSize="large">
              <LoaderButton
                block
                type="submit"
                bsSize="large"
                isLoading={isLoading}
                disabled={!validateEmail()}
              >
                Request Password Reset
              </LoaderButton>
            </FormGroup>
          </form>
          {showPasswordResetAlert && 
            <Fragment>
              <Alert bsStyle="success"><p>Your password has been reset.</p>Please check your email.</Alert>
            </Fragment>
          }
          {showNoUserAlert && 
            <Fragment>
              <Alert bsStyle="warning"><p>That user does not exist.</p>Please check the email address you have entered.</Alert>
            </Fragment>
          }
          {showResetFailedAlert && 
            <Fragment>
              <Alert bsStyle="danger"><p>An error occurred and we were unable to reset your password.</p>Please contact us so we can fix it for you.</Alert>
            </Fragment>
          }
        </div>
      </div>
    );
  }

  return (
    <div>
      {newPasswordForm ? renderNewPasswordForm() : renderLoginForm()}
    </div>
  );
}