
export function getCurrentDateTimeGb(separator='', includeTime=false) {
    var timeSep = ':'
    var newDate = new Date();
    var day = newDate.getDate();
    var month = newDate.getMonth() + 1;
    var year = newDate.getFullYear();
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var seconds = newDate.getSeconds();

    var timeString = `${day<10?`0${day}`:`${day}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`;
    if(includeTime) {
        timeString += `${' '}${hours<10?`0${hours}`:`${hours}`}${timeSep}${minutes<10?`0${minutes}`:`${minutes}`}${timeSep}${seconds<10?`0${seconds}`:`${seconds}`}`;
    }
    return timeString;
}