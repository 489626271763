import React, { useState, useEffect, Fragment } from "react";
import AxAPI from "../libs/axiosLib";
import { CSVLink } from "react-csv";
import moment from 'moment';
//import { useAppContext } from "../libs/contextLib";
import {
    FormGroup,
    FormControl,
    ControlLabel,
    PageHeader,
    Form,
    //Col,
    Panel,
    Table,
    Glyphicon,
    Alert
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import "./AdminReports.css";

export default function AdminReports() {
    //const { authentication } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [trials, setTrials] = useState([]);
    const [sites, setSites] = useState([]);
    const [trialReportName, setTrialReportName] = useState('');
    const [trialSummaryData, setTrialSummaryData] = useState([]);
    const [trialSummaryDataDI, setTrialSummaryDataDI] = useState([]);
    const [trialSummaryReportReady, setTrialSummaryReportReady] = useState(false);
    const [showNoTrialSummaryReportAlert, setShowNoTrialSummaryReportAlert] = useState(false);
    const [eligibilityReportTrialId, setEligibilityReportTrialId] = useState('All');
    const [eligibilityReportStartDate, setEligibilityReportStartDate] = useState('2020-01-01');
    const [eligibilityReportEndDate, setEligibilityReportEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [eligibilityReportData, setEligibilityReportData] = useState([]);
    const [eligibilityReportReady, setEligibilityReportReady] = useState(false);
    const [showNoEligibilityReportAlert, setShowNoElibilityReportAlert] = useState(false);
    const [userSummary, setUserSummary] = useState(null);
    const [imagePatientSummary, setImagePatientSummary] = useState([]);
    const [imageSummaryTrialId, setImageSummaryTrialId] = useState('All');
    const [imageSummaryTrialNames, setImageSummaryTrialNames] = useState([]);
    const [reportTrialId, setReportTrialId] = useState('');
    const [reportSiteId, setReportSiteId] = useState('all');
    const [reportSites, setReportSites] = useState([]);
    const [startDate, setStartDate] = useState('2020-01-01');
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [showNoSitesAlert, setShowNoSitesAlert] = useState(false);

    useEffect(() => {
        async function onLoad() {
          try {
            await loadData();
          } catch (e) {
            onError(e);
          }
        }
             
        onLoad();
    }, []);

    async function loadData() {
        setIsLoading(true);
        
        var url = "trialdata/getalltrials";
        var trialsResult = await AxAPI.get(url);
        setTrials(trialsResult.data.results);

        url = "trialdata/getsites?includeinactive=true";
        var sitesResult = await AxAPI.get(url);
        setSites(sitesResult.data);

        setIsLoading(false);
    }

    async function handleSelectReportTrial(trialId) {
        setIsLoading(true);
        
        setReportSites([]);
        setReportTrialId(trialId);
        
        if(trialId !== '') {
            var trialName = trials.find(element => element.trialId === trialId).trialName;
            setTrialReportName(trialName);
            var url = `trialdata/gettrialsites?trialid=${trialId}`;
            var sitesResult = await AxAPI.get(url);
            var reportingSitesArray = [];
            sitesResult.data.results.forEach(trialSite => {
                var newReportingSite = {
                    id: trialSite.id,
                    siteId: trialSite.siteId,
                    trialId: trialSite.trialId,
                    siteName: sites.find(element => element.siteId === trialSite.siteId).siteName
                };
                reportingSitesArray.push(newReportingSite);
            });
            if(reportingSitesArray.length > 0) {
                setReportSites(reportingSitesArray);
            } else {
                setShowNoSitesAlert(true);
            }
        }
        setIsLoading(false);
    }

    function handleSelectImageSummaryTrial(trialId) {
        setImageSummaryTrialId(trialId);
    }

    function handleSelectReportSite(siteId) {
        setReportSiteId(siteId);
    }

    async function handleGetReport(event) {
        event.preventDefault();
        
        setTrialSummaryReportReady(false);
        setShowNoTrialSummaryReportAlert(false);

        setIsLoading(true);
        var url = `reporting/gettrialreport?trialid=${reportTrialId}&siteid=${reportSiteId}`;
        var report = await AxAPI.get(url);
        var reportOutput = [];
        var reportOutputDI = [];

        if(report.data.results.length !== 0) {
            report.data.results.forEach(reportRow => {
                // Full report
                var siteName = sites.find(element => element.siteId === reportRow.siteId).siteName;
                var newReportRow = {
                    'Site Name': siteName,
                    'First Name': reportRow.patientFirstName,
                    'Last Name': reportRow.patientLastName,
                    'Email': reportRow.patientEmail,
                    'Phone': reportRow.patientPhone,
                    'Patient ID': reportRow.patientId,
                    'Status': reportRow.patientStatus,
                    'Screening Date': reportRow.screeningDate,
                    'Screening Date 2': reportRow.screeningDate2,
                    'Screening Number': reportRow.screeningNumber,
                    'Randomisation Number': reportRow.randomisationNumber,
                    'Last Updated': reportRow.lastUpdatedDateTime,
                    'Last Updated User': reportRow.lastUpdatedUserName,
                    'Disqualification Reason': reportRow.disqualificationReason,
                    'Disqualification Other': reportRow.disqualificationReasonOther,
                    'ICF Sent': reportRow.icfSent,
                    'Notified DateTime': reportRow.notifiedDateTime,
                    'Notification Template ID': reportRow.notificationTemplateId,
                    'Contacts': reportRow.contacts,
                    'Total Contacts': reportRow.totalContacts
                };
                reportOutput.push(newReportRow);

                // Deidentified report
                var newReportRowDI = {
                    'Site Name': siteName,
                    'Patient ID': reportRow.patientId,
                    'Status': reportRow.patientStatus,
                    'Screening Date': reportRow.screeningDate,
                    'Screening Date 2': reportRow.screeningDate2,
                    'Screening Number': reportRow.screeningNumber,
                    'Randomisation Number': reportRow.randomisationNumber,
                    'Last Updated': reportRow.lastUpdatedDateTime,
                    'Last Updated User': reportRow.lastUpdatedUserName,
                    'Disqualification Reason': reportRow.disqualificationReason,
                    'Disqualification Other': reportRow.disqualificationReasonOther,
                    'ICF Sent': reportRow.icfSent,
                    'Total Contacts': reportRow.totalContacts
                };
                reportOutputDI.push(newReportRowDI);
            });
            
            setTrialSummaryData(reportOutput);
            setTrialSummaryDataDI(reportOutputDI);
            setTrialSummaryReportReady(true);
        } else {
            setTrialSummaryData([]);
            setTrialSummaryDataDI([]);
            setTrialSummaryReportReady(false);
            setShowNoTrialSummaryReportAlert(true);
        }
        setIsLoading(false);
    }

    function handleSetStartDate(sDate) {
        setStartDate(sDate);
    }

    function handleSetEndDate(eDate) {
        setEndDate(eDate)
    }

    async function handleGetSummary(event) {
        event.preventDefault();

        setIsLoading(true);
        
        var url = 'reporting/getusersummaryreport';
        var userSummary = await AxAPI.get(url);
        setUserSummary(userSummary.data.results);

        setIsLoading(false);
    }

    async function handleGetImagePatientSummary(event) {
        event.preventDefault();

        setIsLoading(true);

        var url = `reporting/getimagepatientsummaryreport?startdate=${startDate}&enddate=${endDate}&trialid=${imageSummaryTrialId}`;
        var imagePatientSummary = await AxAPI.get(url);

        var trialNames = [];
        imagePatientSummary.data.results.map(item => {
            var found = trialNames.find(x => x === item.trialName);
            if(!found) {
                trialNames.push(item.trialName);
            }
            return trialNames;
        });
        setImageSummaryTrialNames(trialNames);
        setImagePatientSummary(imagePatientSummary.data.results);

        setIsLoading(false);
    }

    function handleSelectEligibilityReportTrial(trialId) {
        setShowNoElibilityReportAlert(false);
        setEligibilityReportReady(false);
        setEligibilityReportTrialId(trialId);
    }

    function handleSetEligibilityStartDate(sDate) {
        setShowNoElibilityReportAlert(false);
        setEligibilityReportReady(false);
        setEligibilityReportStartDate(sDate);
    }

    function handleSetEligibilityEndDate(eDate) {
        setShowNoElibilityReportAlert(false);
        setEligibilityReportReady(false);
        setEligibilityReportEndDate(eDate)
    }

    async function handleGetEligibilityReport(event) {
        event.preventDefault();

        setIsLoading(true);
        setEligibilityReportReady(false);
        setShowNoElibilityReportAlert(false);
        setEligibilityReportData([]);

        var url = `reporting/geteligibilityreport?startdate=${eligibilityReportStartDate}&enddate=${eligibilityReportEndDate}&trialid=${eligibilityReportTrialId}`;
        var response = await AxAPI.get(url);
        
        if(response.data.results.length === 0) {
            setShowNoElibilityReportAlert(true);
        } else {
            setEligibilityReportData(response.data.results);
            setEligibilityReportReady(true);
        }
        setIsLoading(false);
    }

    return (
        <div>
            <div className="AdminPanel">
                <PageHeader>Admin Reporting {isLoading && <Glyphicon glyph="refresh" className="spinning" />}</PageHeader>
                <Panel id="systemSummary">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>System Summary</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div className="summaryTable">
                                {userSummary && 
                                    <Fragment>
                                        <Table responsive condensed striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Admins</th>
                                                    <th>Site Users</th>
                                                    <th>Sponsors</th>
                                                    <th>Patients</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{userSummary.usersByType["Admins"]}</td>
                                                    <td>{userSummary.usersByType["Site Users"]}</td>
                                                    <td>{userSummary.usersByType["Sponsors"]}</td>
                                                    <td>{userSummary.usersByType["Patients"]}</td>
                                                    <td>{userSummary.totalUsers}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Fragment>
                                }
                                <Form onSubmit={handleGetSummary}>
                                    <LoaderButton
                                        block
                                        type="submit"
                                        bsSize="large"
                                        isLoading={isLoading}
                                        >
                                        Get Summary
                                    </LoaderButton>
                                </Form>
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel id="imagePatientSummary">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>Image/Patient Summary</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div>
                                <Form inline onSubmit={handleGetImagePatientSummary}>
                                    <FormGroup className="inlineForm" controlId="imagetrial">
                                        <ControlLabel className="inlineForm" >Trial</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            bsSize="lg"
                                            onChange={e => handleSelectImageSummaryTrial(e.target.value)}
                                            value={imageSummaryTrialId}>
                                            <option key="All" value="All">All trials</option>
                                            {[].concat(trials).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((trial, index) => {
                                                return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                                            })}
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className="inlineForm" controlId="startdate">
                                        <ControlLabel className="inlineForm" >Start Date</ControlLabel>
                                        <FormControl
                                            type="date"
                                            onChange={e => handleSetStartDate(e.target.value)}
                                            value={startDate}
                                            bsSize="lg"
                                        />
                                    </FormGroup>
                                    <FormGroup className="inlineForm" controlId="enddate">
                                        <ControlLabel className="inlineForm" >End Date</ControlLabel>
                                        <FormControl
                                            type="date"
                                            onChange={e => handleSetEndDate(e.target.value)}
                                            value={endDate}
                                            bsSize="lg"
                                        />
                                    </FormGroup>
                                    <FormGroup className="inlineForm"  controlId="submitButton">
                                        <LoaderButton
                                            block
                                            type="submit"
                                            bsSize="large"
                                            isLoading={isLoading}
                                            >
                                            Get Summary
                                        </LoaderButton>
                                    </FormGroup>
                                </Form>
                                {imagePatientSummary.length > 0 && 
                                    imageSummaryTrialNames.map((trialName) => {
                                        return(
                                        <Fragment key={trialName}>
                                            <h3>{trialName}</h3>
                                            <Table responsive condensed striped bordered hover>
                                            <thead>
                                                 <tr>
                                                     <th></th>
                                                     <th></th>
                                                     <th>Images</th>
                                                     <th></th>
                                                     <th></th>
                                                     <th></th>
                                                     <th>Patients</th>
                                                     <th></th>
                                                     <th></th>
                                                 </tr>
                                                 <tr>
                                                     <th>Site Name</th>
                                                     <th>Pending</th>
                                                     <th>Approved</th>
                                                     <th>Disq</th>
                                                     <th></th>
                                                     <th>New</th>
                                                     <th>Pending</th>
                                                     <th>Disq</th>
                                                     <th>Eligible</th>
                                                 </tr>
                                             </thead>
                                             <tbody>
                                                 {
                                                     [].concat(imagePatientSummary).sort((a,b) => a.siteName > b.siteName ? 1 : -1).map((summaryRow) => {
                                                         return(
                                                            (summaryRow.trialName === trialName) &&
                                                            <tr key={summaryRow.siteId}>
                                                                <td>{summaryRow.siteName}</td>
                                                                <td>{summaryRow.imagesPending}</td>
                                                                <td>{summaryRow.imagesApproved}</td>
                                                                <td>{summaryRow.imagesDisqualified}</td>
                                                                <td></td>
                                                                <td>{summaryRow.patientsNew}</td>
                                                                <td>{summaryRow.patientsPending}</td>
                                                                <td>{summaryRow.patientsDisqualified}</td>
                                                                <td>{summaryRow.patientsEligible}</td>
                                                            </tr>
                                                         )})
                                                 }
                                             </tbody>
                                            </Table>
                                        </Fragment>
                                        )
                                    })
                                }
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel id="eligibilityReport">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>Trial Eligibility Report</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div>
                                <Form inline onSubmit={handleGetEligibilityReport}>
                                    <FormGroup className="inlineForm" controlId="eligibilityTrial">
                                        <ControlLabel className="inlineForm" >Trial</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            bsSize="lg"
                                            onChange={e => handleSelectEligibilityReportTrial(e.target.value)}
                                            value={eligibilityReportTrialId}>
                                            <option key="All" value="All">All trials</option>
                                            {[].concat(trials).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((trial, index) => {
                                                return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                                            })}
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className="inlineForm" controlId="eligibilityStartDate">
                                        <ControlLabel className="inlineForm" >Start Date</ControlLabel>
                                        <FormControl
                                            type="date"
                                            onChange={e => handleSetEligibilityStartDate(e.target.value)}
                                            value={eligibilityReportStartDate}
                                            bsSize="lg"
                                        />
                                    </FormGroup>
                                    <FormGroup className="inlineForm" controlId="eligibilityEndDate">
                                        <ControlLabel className="inlineForm" >End Date</ControlLabel>
                                        <FormControl
                                            type="date"
                                            onChange={e => handleSetEligibilityEndDate(e.target.value)}
                                            value={eligibilityReportEndDate}
                                            bsSize="lg"
                                        />
                                    </FormGroup>
                                    <FormGroup className="inlineForm"  controlId="eligibilitySubmitButton">
                                        <LoaderButton
                                            block
                                            type="submit"
                                            bsSize="large"
                                            isLoading={isLoading}
                                            >
                                            Get Report
                                        </LoaderButton>
                                    </FormGroup>
                                </Form>
                                {eligibilityReportReady && 
                                    <Fragment>
                                        <CSVLink data={eligibilityReportData} filename={'EligibilityReport.csv'}>Save as CSV</CSVLink>
                                    </Fragment>
                                }
                                {showNoEligibilityReportAlert &&
                                    <Fragment>
                                        <Alert bsStyle="warning">There are no results for that report.</Alert>
                                    </Fragment>
                                }
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel id="trialReport">
                    <Panel.Heading className="panelHeading">
                        <Panel.Title className="panelHeading" toggle>Trial Summary</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div>
                                <Form inline onSubmit={handleGetReport}>
                                    <FormGroup className="inlineForm" controlId="reporttrial">
                                        <ControlLabel className="inlineForm" >Trial</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            bsSize="lg"
                                            onChange={e => handleSelectReportTrial(e.target.value)}
                                            value={reportTrialId}>
                                            <option key="none" value=""></option>
                                            {[].concat(trials).sort((a,b) => a.trialName > b.trialName ? 1 : -1).map((trial, index) => {
                                                return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                                            })}
                                        </FormControl>
                                    </FormGroup>
                                    {reportSites.length !== 0 &&
                                    <Fragment>
                                        <FormGroup className="inlineForm"  controlId="sites" bsSize="lg">
                                            <ControlLabel className="inlineForm">Site</ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                onChange={e => handleSelectReportSite(e.target.value)}
                                                value={reportSiteId}>
                                                <option key="all" value="all">All sites</option>
                                                {[].concat(reportSites).sort((a,b) => a.siteName > b.siteName ? 1 : -1).map((site, index) => {
                                                    return (<option key={index} value={site.siteId}>{site.siteName}</option>)
                                                })}
                                            </FormControl>
                                        </FormGroup>
                                        <FormGroup className="inlineForm">
                                            <LoaderButton
                                                block
                                                type="submit"
                                                bsSize="large"
                                                isLoading={isLoading}
                                                >
                                                Get Report
                                            </LoaderButton>
                                        </FormGroup>
                                        {trialSummaryReportReady && 
                                            <Fragment>
                                                <p>
                                                <CSVLink data={trialSummaryData} filename={`${trialReportName}_fullReport.csv`}>Full: Save as CSV</CSVLink>
                                                </p>
                                                <p>
                                                    <CSVLink data={trialSummaryDataDI} filename={`${trialReportName}_deidentifiedReport.csv`}>Deidentified: Save as CSV</CSVLink>
                                                </p>
                                            </Fragment>
                                        }
                                        {showNoTrialSummaryReportAlert &&
                                            <Fragment>
                                                <Alert bsStyle="warning">There are no results for that report.</Alert>
                                            </Fragment>
                                        }
                                    </Fragment>}
                                    {showNoSitesAlert && 
                                        <Fragment>
                                            <Alert bsStyle="warning">There are no active sites for that trial.</Alert>
                                        </Fragment>
                                    }
                                </Form>
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        </div>
    );
}