import React, { useState, useEffect, Fragment } from "react";
import { onError } from "../libs/errorLib";
import AxAPI from "../libs/axiosLib";
import moment from 'moment';
import _ from 'lodash';
import {
    FormGroup,
    FormControl,
    ControlLabel,
    PageHeader,
    Panel,
    Table,
    Button,
    Modal,
    Checkbox,
    ButtonToolbar,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ContactPipeline from "../components/ContactPipeline";
import * as uuid from "uuid";
import { getCurrentDateTimeGb } from "../libs/dateTimeLib";
import { useAppContext } from "../libs/contextLib";
import ContactManagementModal from '../components/ContactManagementModal';
import "./ContactManagementPanel.css";

export default function ContactManagementPanel({ impersonateSiteId }) {
    const { authentication } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [trials, setTrials] = useState([]);
    const [patients, setPatients] = useState([]);
    const [trialId, setTrialId] = useState('');
    const [patientsSortHeader, setPatientsSortHeader] = useState('lastContacted');
    const [patientsSortDir, setPatientsSortDir] = useState(1);
    const [patientToEdit, setPatientToEdit] = useState(null);
    const [viewBy, setViewBy] = useState('dnd');
    const [includeICF, setIncludeICF] = useState(true);
    const [includeScreeningBooked, setIncludeScreeningBooked] = useState(true);
    const [includeRandomisation, setIncludeRandomisation] = useState(true);
    
    const [showModalDisq, setShowModalDisq] = useState(false);
    const [patientDisqReasons, setPatientDisqReasons] = useState([]);
    const [otherDisq, setOtherDisq] = useState('');
    const [disqReason, setDisqReason] = useState('');
    const [disqPatient, setDisqualifyingPatient] = useState({});

    useEffect(() => {
        async function onLoad() {
            try {
                setIsLoading(true);
        
                var url = `trialdata/getsitetrials?siteid=${impersonateSiteId === '' ? authentication.userDetails["custom:Location"] : impersonateSiteId}`;
                var result = await AxAPI.get(url);
                setTrials(result.data.results);
            
                setIsLoading(false);
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [authentication.userDetails, impersonateSiteId]);

    async function handleLoadPatients(event) {
        event.preventDefault();
        await loadPatients();
        await getDisqReasons();
    }

    async function getDisqReasons() {
        setIsLoading(true);
        var url = `trialData/getpatientdisqreasons?trialid=${trialId}`
        var result = await AxAPI.get(url);
        setPatientDisqReasons(result.data);
        setIsLoading(false);
    }

    async function loadPatients() {
        setIsLoading(true);

        //A nicer way to handle this is using URLSearchParams() but browser compatibility might not work with older machines running IE
        
        const icfFilter = includeICF ? '&includeICF=true' : '';
        const randomisationFilter = includeRandomisation ? '&includeRandomisation=true' : '';
        const screeningFilter = includeScreeningBooked ? '&includeScreeningBooked=true' : '';
        var url = `patients/getapprovedpatients?trialid=${trialId}&siteid=${impersonateSiteId === '' ? authentication.userDetails["custom:Location"] : impersonateSiteId}&usersub=${authentication.userDetails.sub}${icfFilter}${randomisationFilter}${screeningFilter}`;
        var res = await AxAPI.get(url);
        setIsLoading(false);
        if (res.data.results.length === 0) {
            setPatients([]);
            window.alert('There are currently no approved patients for this trial.');
        } else {
            //Figure out last contacted date - Data is currently ordered so may not be completely necessary
            const patientData = res.data.results.map(patient => {
                if(!patient.contactData || patient.contactData.length === 0){
                    return patient
                }
                const lastContacted = patient.contactData.sort((a,b) => moment(a.contactDateTime, 'DD/MM/YYYY hh:mm:ss') > moment(b.contactDateTime, 'DD/MM/YYYY hh:mm:ss') ? -1 : 1)[0].contactDateTime;
                return {
                    ...patient,
                    lastContacted: moment(lastContacted, 'DD/MM/YYYY hh:mm:ss')
                }
            })
            setPatients(patientData);
        }
    }

    function validateForm() {
        return trialId.length > 0;
    }

    function changePatientSort(headerName) {
        var newSortDir = headerName === patientsSortHeader ? -patientsSortDir : 1;
        setPatientsSortHeader(headerName);
        setPatientsSortDir(newSortDir);
    }

    function getUsersArray() {
        var usersArray;
        switch (patientsSortHeader) {
            case 'patientId':
                usersArray = [].concat(patients).sort((a, b) => a.userData.patientId > b.userData.patientId ? patientsSortDir : -patientsSortDir);
                break;
            case 'email':
                usersArray = [].concat(patients).sort((a, b) => a.userData.email > b.userData.email ? patientsSortDir : -patientsSortDir);
                break;
            case 'phone':
                usersArray = [].concat(patients).sort((a, b) => a.userData.phoneNumber > b.userData.phoneNumber ? patientsSortDir : -patientsSortDir);
                break;
            case 'familyName':
                usersArray = [].concat(patients).sort((a, b) => a.userData.familyName > b.userData.familyName ? patientsSortDir : -patientsSortDir);
                break;
            case 'givenName':
                usersArray = [].concat(patients).sort((a, b) => a.userData.givenName > b.userData.givenName ? patientsSortDir : -patientsSortDir);
                break;
            case 'contacts':
                usersArray = [].concat(patients).sort((a, b) => a.contactData.length > b.contactData.length ? patientsSortDir : -patientsSortDir);
                break;
            case 'lastContacted':
                usersArray = [].concat(patients).sort((a, b) => (a.lastContacted || moment()).isAfter(b.lastContacted || moment()) ? patientsSortDir : -patientsSortDir);
                break;
            default:
                break;
        }
        return usersArray;
    }

    function handleShowDetailsModal(passedUser) {
        setPatientToEdit(passedUser);
    }

    async function handlePatientMove(cognitoUserSub, newStatus) {
        const patient = patients.find(pat => pat.userData.cognitoUserSub === cognitoUserSub);
        if(newStatus === 'Disqualified'){
            //TODO: card is jumping back to original column before reason is selected - perhaps need to actually set the new status then revert in handleCloseModalDisq function if they don't follow through?
            setDisqualifyingPatient(patient);
            setShowModalDisq(true);
            return;
        }

        return savePatientMove(patient, newStatus);
    }

    async function savePatientMove(patient, newStatus) {
        //If the status is DQ'd - show modal, otherwise save the user's data
        const currentStatus = patient.trialData.status;
        patient.trialData.status = newStatus;
        const newPatients = _.union([patient], patients);
        //Set the patients at this stage to ensure item appears in correct column
        setPatients(newPatients);

        try {
            //Don't need the response at this stage as the F/E has already handled the status for itself
            await AxAPI.post("patients/savepatienttrialstatus", patient.trialData);
        }
        catch (err) {
            //Reset the patient status and set the patients list back on error
            patient.trialData.status = currentStatus;
            const oldPatients = _.union([patient], patients);
            setPatients(oldPatients);
            //TODO: show error?
        }
    }

    async function handleDisqualify() {
        
        let patient = disqPatient;
        patient.trialData.disqualificationReason = disqReason;
        patient.trialData.disqualificationReasonOther = otherDisq;
        await savePatientMove(disqPatient, 'Disqualified');
        setDisqualifyingPatient({});
        setShowModalDisq(false);
        setDisqReason('');
    }

    function handleCloseModalDisq() {
        setDisqReason('');
        setShowModalDisq(false);
    }

    function handleCloseDetailsModal() {
        setPatientToEdit(null);
    }

    async function handleRecordNewContact(newContactFields) {

        setIsLoading(true);
        var contactData = {
            Id: uuid.v1(),
            UserId: authentication.userDetails.sub,
            PatientId: patientToEdit.userData.cognitoUserSub,
            TrialId: trialId,
            SiteId: impersonateSiteId === '' ? authentication.userDetails["custom:Location"] : impersonateSiteId,
            ContactType: newContactFields.contactType,
            ContactDetails: newContactFields.contactDetails,
            ContactStatus: 'Pending',
            ContactDateTime: getCurrentDateTimeGb('-', true),
        };

        await AxAPI.post("patients/savepatientcontact", contactData);

        // Close modal and reload patients list.
        setIsLoading(false);
        handleCloseDetailsModal();
        newContactFields.contactType = "";
        newContactFields.contactDetails = "";
        await loadPatients();
        // Or do we leave the modal open and just refresh the contacts list and clear the contact form?
    }

    async function handleUpdatePatientTrial(patientTrialFields) {
        setIsLoading(true);

        const patientData = {
            ...patientToEdit.trialData,
            ...patientTrialFields,
            icfSent: patientTrialFields.icfSent === 'true' ? true : patientTrialFields.icfSent === 'false' ? false : patientTrialFields.icfSent,
            screeningBooked: patientTrialFields.screeningBooked === 'true' ? true : patientTrialFields.screeningBooked === 'false' ? false : patientTrialFields.screeningBooked,
        };

        await AxAPI.post("patients/savepatienttrialstatus", patientData);
        // // Close modal and reload patients list.
        handleCloseDetailsModal();
        await loadPatients();
        // Or do we leave the modal open and just refresh the contacts list and clear the contact form?
    }

    return (
        <div className="ContactManagementPanel">
            <PageHeader>Patient Contact Management</PageHeader>
            <Panel>
                <Panel.Heading>Contacts</Panel.Heading>
                <Panel.Body>
                    <div className="content">
                        {trials && (
                            <form className="frmPanel" onSubmit={handleLoadPatients}>
                                <FormGroup className="flexrow" controlId="trialNameContacts" bsSize="large">
                                    <div className="flexCol">
                                        <ControlLabel>Trial Name</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Select a trial"
                                            onChange={e => setTrialId(e.target.value)}
                                            value={trialId}>
                                            <option key="none" value=""></option>
                                            {[].concat(trials).sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map((trial, index) => {
                                                return (<option key={index} value={trial.trialId}>{trial.trialName}</option>)
                                            })}
                                        </FormControl>
                                    </div>
                                    <div className="flexcol">
                                        <ControlLabel>
                                            Include fields:
                                        </ControlLabel>
                                        <div>
                                            <Checkbox
                                            inline
                                            checked={includeICF}
                                            onChange={e => setIncludeICF(e.target.checked)}
                                            >ICF Sent
                                            </Checkbox>
                                        </div>
                                        <div>
                                            <Checkbox
                                            inline
                                            checked={includeScreeningBooked}
                                            onChange={e => setIncludeScreeningBooked(e.target.checked)}
                                            >Screening booked
                                            </Checkbox>
                                        </div>
                                        <div>
                                            <Checkbox
                                            inline
                                            checked={includeRandomisation}
                                            onChange={e => setIncludeRandomisation(e.target.checked)}
                                            >Has randomisation number
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <div className="flexcol">
                                        <LoaderButton
                                            className="btnSmall"
                                            type="submit"
                                            bsSize="large"
                                            bsStyle="success"
                                            isLoading={isLoading}
                                            disabled={!validateForm()}
                                        >
                                            View Patients
                                        </LoaderButton>
                                    </div>
                                </FormGroup>
                            </form>
                        )}
                        
                    </div>
                </Panel.Body>
            </Panel>
            {patients && patients.length > 0 && (
                <Fragment>
                    <Panel>
                        <Panel.Heading>View by</Panel.Heading>
                        <div className="content">
                            <ButtonToolbar>
                                <ToggleButtonGroup type="radio" name="options" defaultValue={viewBy} onChange={setViewBy}>
                                    <ToggleButton value="dnd">List</ToggleButton>
                                    <ToggleButton value="table">Table</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                    </Panel>
                    <Panel>
                        <Panel.Body>
                            <Fragment>
                                {viewBy === 'table' ? (
                                    <Table responsive striped bordered condensed hover>
                                        <thead>
                                            <tr key="header">
                                                <th className="sortableTableHeader" onClick={e => changePatientSort("patientId")}>Patient ID</th>
                                                <th className="sortableTableHeader" onClick={e => changePatientSort("givenName")}>First Name</th>
                                                <th className="sortableTableHeader" onClick={e => changePatientSort("familyName")}>Last Name</th>
                                                <th className="sortableTableHeader" onClick={e => changePatientSort("email")}>Email</th>
                                                <th className="sortableTableHeader" onClick={e => changePatientSort("phone")}>Phone</th>
                                                <th className="sortableTableHeader" onClick={e => changePatientSort("contacts")}>Contact Attempts</th>
                                                <th className="sortableTableHeader" onClick={e => changePatientSort("lastContacted")}>Last Contacted</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getUsersArray().map((patient, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{patient.userData.patientId}</td>
                                                        <td>{patient.userData.givenName}</td>
                                                        <td>{patient.userData.familyName}</td>
                                                        <td>{patient.userData.email}</td>
                                                        <td>{patient.userData.phoneNumber}</td>
                                                        <td>{patient.contactData.length}</td>
                                                        <td>{patient.lastContacted ? patient.lastContacted.fromNow() : ''}</td>
                                                        <td><Button onClick={e => handleShowDetailsModal(patient)}>Show</Button></td>
                                                    </tr>)
                                            })}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <ContactPipeline
                                        onClickPatient={handleShowDetailsModal}
                                        recordPatientMoved={handlePatientMove}
                                        patients={patients} />
                                )}
                            </Fragment>
                        </Panel.Body>
                    </Panel>
                </Fragment>
            )}

            {patientToEdit && (
                <ContactManagementModal
                    disqualificationReasons={patientDisqReasons}
                    handleCloseDetailsModal={handleCloseDetailsModal}
                    handleRecordNewContact={handleRecordNewContact}
                    handleUpdatePatientTrial={handleUpdatePatientTrial}
                    isLoading={isLoading}
                    patientToEdit={patientToEdit}
                />
            )}
            <Modal
                dialogClassName="modalDialog"
                bsSize="large"
                show={showModalDisq}
                onHide={handleCloseModalDisq}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Disqualify Patient</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalFlexRow">
                        <div className="modalFlexCol">
                            <FormControl
                            componentClass="select"
                            bsSize="large"
                            placeholder="Disqualification reason"
                            onChange={e => setDisqReason(e.target.value)}
                            value={disqReason}>
                            <option key="none" value=""></option>
                                {[].concat(patientDisqReasons).sort((a,b) => a.displayOrder > b.displayOrder ? 1 : -1).map((reason, index) => {
                                return (<option key={index} value={reason.disqReasonText}>{reason.disqReasonText}</option>)
                                })}
                                <option key="other" value="other">Other</option>
                            </FormControl>
                            {disqReason === 'other' && (
                                <FormControl
                                    type="text"
                                    bsSize="large"
                                    value={otherDisq}
                                    onChange={e => setOtherDisq(e.target.value)}>
                                </FormControl>
                            )}
                        </div>
                        <div>
                            <Button
                                className="btnSmall"
                                type="submit"
                                bsSize="small"
                                bsStyle="danger"
                                disabled={disqReason === '' || (disqReason==='other' && otherDisq === '')}
                                onClick={handleDisqualify}>
                                    Disqualify
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModalDisq}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}